import React from 'react';
import axios from 'axios';
import ProfileMenu from '../components/ProfileMenu'
import {useEffect, useState } from 'react'
import Session from '../components/AdminSession'
import Chinaclock from '../components/Clock'
import { Link } from 'react-router-dom'

export const AdminSessions = () => {

  axios.defaults.withCredentials = true;
  const [availableSessions, setAvailableSessions] = useState([]);

  useEffect(() => {

    axios.get(process.env.REACT_APP_Apiurl + "login")
        .then((res) => {
            if(!res.data.admin){
            console.log(res)
            window.location.href = process.env.REACT_APP_App_Url
            }
        })
        axios.get(process.env.REACT_APP_Apiurl + "admin/Sessions")
        .then((res) => {
          if(res.data.message === "No Sessions Found"){
              setAvailableSessions(null);
          }else{
              setAvailableSessions(res.data.session)
          }
          
        })
  },[]);

  const handleItemClick = (Num) => {
    var targetUrl = process.env.REACT_APP_App_Url+"Admin/Session/" + Num;
    window.location.href = targetUrl;
    };

  return (
    <div className = "bg-gray-200 w-[100vw] h-[100vh] flex flex-row">
        
        <div className='w-[15%] h-[100vh]'>
            <ProfileMenu/>
        </div>

        <div className="flex-col w-[85%] border-x-[2vw]">
            <div className = " w-full mt-[4vh] flex md:space-x-4">
                <div className = "py-2 min-w-[60%] rounded-md bg-white text-left flex items-center space-x-1 ">
                    <div className = 'grow text-left'>
                        <p className = "font-semibold ml-4">Sessions</p>
                    </div>
                    <div className = 'grow text-right'>
                        <Link className = "cursor-pointer text-orange-500 hover:text-orange-400 mr-4" to = "../Admin/Sessions/Create">Create New</Link>
                        
                    </div>
                </div>
                <Chinaclock/>
            </div>
            <div className='w-full'>
                <div className=' max-h-[800px] w-full my-4 rounded-lg pb-6 bg-white text-center relative'>
                    <p className='text-2xl pt-[3.5vh] font-bold'>Available Sessions</p>
                    <p className='text-sm pb-[2vh]'>Click to view session</p>

                        <div className='overflow-y-auto h-[42rem] flex-wrap'>
                            {availableSessions === null ? (
                                    <div>No Session Data Found</div>
                                ) : (
                                    /* Map over the array of fetched items */
                                    availableSessions.map((item) => ( 
                                    <div key={item.SessionNo} onClick = {() => handleItemClick(item.SessionNo)}>
                                        <Session 
                                        SessionNum = {item.SessionNo}
                                        StartDate = {item.Start}
                                        EndDate = {item.End}
                                        DueDate = {item.Due}
                                        Status = {item.SessionStatus}
                                        SubmissionDate = {item.SubmissionDate}
                                        userStatus= {item.UserStatus}
                                        IsVisible = {item.Is_Visible}/>
                                    </div>
                                    ))
                                )}
                        </div>
                </div>
            </div>
        </div>
       
    </div>
  )
}
export default AdminSessions