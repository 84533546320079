import React, { useState ,useEffect} from 'react'
import { Link } from 'react-router-dom'
import BG from '../assets/LoginBG.png'
import axios from 'axios'

function SignUp() {

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    accessCode: ''
  })
  

  axios.defaults.withCredentials = true;

  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [success, setSuccess] = useState(false);

  const capitalizeFirstLetter = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join('');
  };

  const handleInput = (event) => {
    setUser(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    setMessage('');
    setShowError(false);
    setSuccess(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(user);
    console.log(user.password.length)

    if(user.accessCode != '0924'){
      setShowError(true);
      setMessage("Incorrect Access Code")
      return
    }

    if(user.password[0].length < 7){
      setShowError(true);
      setMessage("Password must be more than 8 characters")
      return
    }

    axios.post(process.env.REACT_APP_Apiurl + 'signup', user)
      .then(async (res) => {
        setMessage(res.data.message);
        if (!res.data.login){
          setShowError(true);
          setSuccess(false);
        }
        else if (res.data.login){
          setSuccess(true);
          window.setTimeout(function(){

            // Move to a new location or you can do something else
            window.location.href = process.env.REACT_APP_App_Url + "Log-in";
    
        }, 3000);

        }
      })
      .catch(err => console.log(err));
  }


  useEffect(() => {
    axios.get(process.env.REACT_APP_Apiurl + "login")
      .then((res) => {
        if(res.data.login){
          window.location.href = process.env.REACT_APP_App_Url
        }
      })
  })

  return (
    <div style={{ backgroundImage: `url(${BG})` }} className='xl:bg-contain xl:bg-center xl:bg-no-repeat'>Sign-up
      <div className=" mx-auto px-2 pt-[5vh] flex flex-col h-full ">
        <div className="h-[10vh]"></div>


        <div className="bg-white rounded-lg shadow-md w-[350px] h-full self-center text-center pb-5">
          <div className="bg-[#f8740c] rounded-t-lg w-full h-[85px]">
            <h1 className="antialiased text-white text-xl font-bold ">Sign Up</h1>
          </div>
          <form className='mx-4 mt-5' onSubmit={handleSubmit}>

            <div className = {!showError ? "hidden" : "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"} role="alert">
              <strong className="font-bold">Error! </strong>
              <span className="block sm:inline">{message}</span>
            </div>

            <div className = {!success ? "hidden" : "bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"} role="alert">
              <strong className="font-bold">{message}! </strong>
              <span className="block sm:inline">Redirecting...</span>
            </div>

            <label htmlFor="Name" className="text-left font-semibold block text-gray-700 mb-2">Name</label>
            <div className="mb-2 text-left flex flex-nowrap gap-3">
              {/* <label htmlFor="first_name" className="block text-gray-700 mb-2">Name</label> */}
              <input onChange={handleInput} type="text" id="first_name" name="first_name" placeholder="First name" className="border border-gray-300 px-3 py-2 w-1/2 rounded-full focus:outline-none focus:ring focus:border-blue-500" required />

              {/* <label htmlFor="last_name" className="flex-wrap block text-gray-700 font-semibold mb-2"></label> */}
              <input onChange={handleInput} type="text" id="last_name" name="last_name" placeholder="Last name" className="border border-gray-300 px-3 py-2 w-1/2 rounded-full focus:outline-none focus:ring focus:border-blue-500" required />

            </div>

            <div className="mb-4 text-left">
              <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email</label>
              <input onChange={handleInput} type="email" id="email" name="email" placeholder="Email" className="border border-gray-300 px-3 py-2 w-full rounded-full focus:outline-none focus:ring focus:border-blue-500" required />
            </div>
            <div className="mb-4 text-left">
              <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">Password</label>
              <input type="password" id="password" name="password" onChange={handleInput} placeholder="Create password" className="border border-gray-300 px-3 py-2 w-full rounded-full focus:outline-none focus:ring focus:border-blue-500" required />
            </div>
            <div className="mb-4 text-left">
              {/* <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">Confirm Password</label> */}
              <input onChange={handleInput} type="password" id="passwordConfirm" name="passwordConfirm" placeholder="Confirm password" className="border border-gray-300 px-3 py-2 w-full rounded-full focus:outline-none focus:ring focus:border-blue-500" required />
            </div>
            <div className="mb-4 text-left">
              <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">Access Code</label>
              <input onChange={handleInput} type="password" id="accessCode" name="accessCode" placeholder="Access Code" className="border border-gray-300 px-3 py-2 w-full rounded-full focus:outline-none focus:ring focus:border-blue-500" required />
            </div>
            <button type="submit" className="bg-[#f8740c] text-white font-semibold px-4 py-2 hover:bg-[#ff913c] focus:outline-none focus:ring focus:border-blue-300 w-full rounded-full">Sign Up</button>
          </form>

          <h3 className="pt-[20px] text-center text-gray-400 text-sm font-semibold">Already have an account?</h3>
          <Link to='../Log-in' className="text-[#f8740c] text-md font-semibold hover:text-[#ffa45e]">Log in</Link>



        </div>

        <div className="h-[10vh]"></div>

      </div>
    </div>
  )
}

export default SignUp