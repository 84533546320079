export default function ProjectRight({ title, type, description, image }) {
  return (
    <div className="w-[80%] min-w-[350px] max-w-[790px] my-4 text-black md:flex mx-auto items-center projectsmall">
      {/* Image visible on small screens */}
      <img
        src={image}
        alt="Alpha House volunteering"
        className="md:hidden w-[80%] object-cover object-center round "
      />
      
      {/* Content */}
      <div className="p-6 projectRight text-right projectsmall pstyle">
        <h2>{title}</h2>
        <h3 className="project">{type}</h3>
        <p>{description}</p>
      </div>
      
      {/* Image visible on medium screens and larger */}
      <img
        src={image}
        alt="Alpha House volunteering"
        className="hidden md:block md:w-[43%] w-[80%] object-cover object-center round "
      />
    </div>
  );
}
