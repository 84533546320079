import React from "react";
import axios from "axios";
import ProfileMenu from '../components/ProfileMenu'
import { useEffect, useState } from "react";
import SearchInput from "../components/searchInput";
import { useParams,Link } from 'react-router-dom'

export const EditSession = () => {
  axios.defaults.withCredentials = true;
  const [personnelArray, setPersonnelArray] = useState([]);
  const{id} = useParams();
  const [sessionData, setSessionData] = useState({
    Session_Number: "",
    Start_Date: "",
    End_Date: "",
    Due_Date: "",
    Session_Status: "",
    Is_Visible: "",
    included_Personnel: [],
  });

  const [success, setSuccess] = useState("Update");

  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    axios.delete(process.env.REACT_APP_Apiurl + "admin/delete/session/" + id).then((res) => {
      window.setTimeout(function(){
        // Move to a new location or you can do something else
        window.location.href = process.env.REACT_APP_App_Url+"Admin/Sessions";

    }, 1000);
    });

    }
  

  const handleSubmit = () => {
    if (loading) return; // Prevent multiple clicks while loading

    

    setLoading(true);
    sessionData.included_Personnel = selectedItems;

    for (const key in sessionData) {
        if (sessionData.hasOwnProperty(key)) {
           if (sessionData[key] === "") {
            setErrorMessage(`${key.replace(/_/g, ' ')} is empty`);
            setShowError(true);
            setLoading(false);
            return;
          } 
        }
      }

    const { Start_Date, End_Date, Due_Date } = sessionData;

    const startDate = new Date(Start_Date);
    const endDate = new Date(End_Date);
    const dueDate = new Date(Due_Date);

    if (Start_Date && End_Date && startDate >= endDate) {
        setErrorMessage("End Date should be After Start Date");
        setShowError(true);
        setLoading(false);
        return;
      }
  
      if (Due_Date && End_Date && dueDate <= endDate) {
        setErrorMessage("Due Date should be after End Date");
        setShowError(true);
        setLoading(false);
        return;
      }
  

    axios.post(process.env.REACT_APP_Apiurl + "admin/update/session", sessionData)
      .then((res) => {
        setSuccess("Session Updated!")
        window.setTimeout(function(){
            // Move to a new location or you can do something else
            window.location.href = process.env.REACT_APP_App_Url+"Admin/Sessions";
            console.log(sessionData);
    
        }, 1000);
            
          })
          .catch(error => {
            if(error.response){
                if(error.response.status === 409){
                    console.log(error);
                    setErrorMessage(error.response.data);
                    setShowError(true);
                    setLoading(false);
                }
            }
          });
  };

  const handleSelect = (item) => {
    console.log(selectedItems);
    if (!selectedItems.includes(item)) {
      setSelectedItems([...selectedItems, item]);
      setShowError(false);
    }
  };

  const capitalizeFirstLetter = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join("");
  };

  useEffect( () => {
    
    axios.get(process.env.REACT_APP_Apiurl + "login").then((res) => {
      
      if (!res.data.admin) {
        console.log(res);
        window.location.href = process.env.REACT_APP_App_Url;
      }
    });
    
    axios.get(process.env.REACT_APP_Apiurl + "admin/session/edit/" + id).then((res) => {

        sessionData.Session_Number = res.data.sessionData[0].Session_Number;
        sessionData.Start_Date = res.data.sessionData[0].Start;
        sessionData.End_Date = res.data.sessionData[0].End;
        sessionData.Due_Date = res.data.sessionData[0].Due;
        sessionData.Session_Status = res.data.sessionData[0].Session_Status;
        sessionData.Is_Visible = res.data.sessionData[0].Is_Visible;
        sessionData.included_Personnel = res.data.includedPersonnel;
        console.log(sessionData);

        document.getElementById('Session_Number').value = sessionData.Session_Number;
        document.getElementById('Start_Date').value = sessionData.Start_Date;
        document.getElementById('End_Date').value = sessionData.End_Date;
        document.getElementById('Due_Date').value = sessionData.Due_Date;
        document.getElementById('Session_Status').value = sessionData.Session_Status;
        document.getElementById('Is_Visible').value = sessionData.Is_Visible;

        axios.get(process.env.REACT_APP_Apiurl + "admin/all/personnel").then((res2) => {
            console.log(res2);
            console.log(sessionData.included_Personnel);
            setPersonnelArray(res2.data.personnel);

            if(selectedItems.length != sessionData.included_Personnel.length){
              for(let i = 0; i < sessionData.included_Personnel.length; i++){
                    selectedItems.push(res2.data.personnel.find(e => e.Personnel_No === sessionData.included_Personnel[i].PersonnelNo))
                }
          }
            
            });
            

    });

  }, []);

  useEffect( () => {
    
      
  }, [personnelArray])

  const handleInput = (event) => {
    setSessionData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    setShowError(false);
  };

  const handleRemove = (item) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem !== item)
    );
  };

  return (
    <div className="bg-gray-200 w-[100vw] min-h-[100vh] h-[100%] flex flex-row">
      <div className="w-[15%] h-[100vh]">
        <ProfileMenu />
      </div>

      <div className="flex-col h-[100%] pb-4 w-[85%] border-x-[2vw]">
        <div className="  h-[5%] mt-[4vh] flex md:space-x-4">
          <div className=" grow rounded-md bg-white text-left flex items-center space-x-1 ">
            <div className="grow text-left">
              <p className="font-semibold ml-4">Edit Session {id}</p>
            </div>

            <div className={!showError ? "hidden" : "text-red-700 px-4 py-3 rounded text-center"}>
                <strong className="font-bold">Error! </strong>
                <p className="block sm:inline">{errorMessage}</p>
              </div>




            <div className="grow text-right">
              <Link className = "font-semibold cursor-pointer text-red-500 hover:text-red-400 mr-4" to = {`../Admin/Session/${id}`}>Cancel</Link>
              <button
                form="timesheet"
                className="my-2 rounded-md bg-red-500 cursor-pointer text-white hover:bg-red-400 mr-4 py-1 px-3 font-semibold"
                onClick = {handleDelete}
              >
                Delete
              </button>
              <button
                form="timesheet"
                className=" rounded-md bg-orange-500 cursor-pointer text-white hover:bg-orange-400 mr-4 py-1 px-3 font-semibold"
                onClick={handleSubmit}
                
              >
                {success}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full min-h-[87vh]">
          <div className="h-full w-full my-4 rounded-lg py-4 px-4 bg-white text-left relative">
            <div className="">

            
              
              <p className="font-bold text-lg mb-2b"> Session Settings</p>
              <div className="flex">
                <div className="w-[12vw] pr-4">
                  <label
                    for="Session_Number"
                    class="block text-gray-700 text-sm font-semibold"
                  >
                    Session No.
                  </label>
                  <input
                  disabled
                    min="0"
                    id="Session_Number"
                    required
                    form="SessionInfo"
                    onChange={handleInput}
                    type="number"
                    name="Session_Number"
                    placeholder="Session Number"
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="pr-4">
                  <label
                    for="Start_Date"
                    class="block text-gray-700 text-sm font-semibold "
                  >
                    Start Date
                  </label>
                  <input
                    id="Start_Date"
                    required
                    form="Session"
                    onChange={handleInput}
                    type="Date"
                    name="Start_Date"
                    placeholder={sessionData.Start_Date}
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="pr-4">
                  <label
                    for="End_Date"
                    class="block text-gray-700 text-sm font-semibold "
                  >
                    End Date
                  </label>
                  <input
                    id="End_Date"
                    required
                    form="Session"
                    onChange={handleInput}
                    type="Date"
                    name="End_Date"
                    placeholder= {sessionData.End_Date}
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="pr-4">
                  <label
                    for="Due_Date"
                    class="block text-gray-700 text-sm font-semibold "
                  >
                    Due Date
                  </label>
                  <input
                    id="Due_Date"
                    required
                    form="Session"
                    onChange={handleInput}
                    type="Date"
                    name="Due_Date"
                    placeholder= {sessionData.Due_Date}
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <p className="font-bold text-lg mt-3 mb-2">
                {" "}
                Visibility Settings
              </p>
              <div className="flex">
                <div className="pr-4">
                  <label
                    for="Is_Visible"
                    class="block text-gray-700 text-sm font-semibold "
                  >
                    Public Visibility
                  </label>
                  <select
                    id="Is_Visible"
                    required
                    form="SessionInfo"
                    onChange={handleInput}
                    name="Is_Visible"
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value={1}>Visible</option>
                    <option value={0}>Hidden</option>
                  </select>
                </div>
                <div className="pr-4">
                  <label
                    for="Session_Status"
                    class="block text-gray-700 text-sm font-semibold "
                  >
                    Session Status
                  </label>
                  <select
                    id="Session_Status"
                    required
                    form="SessionInfo"
                    onChange={handleInput}
                    name="Session_Status"
                    
                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value={"In Progress"}>In Progress</option>
                    <option value={"Completed"}>Completed</option>
                    <option value={"Upcoming"}>Upcoming</option>
                    <option value={"Under Review"}>Under Review</option>
                  </select>
                </div>
              </div>
              <p className="font-bold text-lg mt-3 mb-2">Teachers</p>
              <div className="">
                <div className="flex">
                  <div>
                    <p className="text-gray-700 text-sm font-semibold ">
                      Personnel
                    </p>
                    <SearchInput
                      onSelect={handleSelect}
                      selectedItems={selectedItems}
                      personnelArray={personnelArray}
                    />
                  </div>

                  <div className="ml-4 w-[40%] max-w-[50%]">
                    <p className="text-gray-700 text-sm font-semibold ">
                      Added
                    </p>
                    <div className=" w-full rounded-md max-h-[40vh] overflow-y-auto">
                      {!selectedItems.length > 0 ? (
                        <div>No users Added</div>
                      ) : (
                        <div className="left-0 right-0 border border-gray-300 bg-white rounded-md">
                          {selectedItems.map((item) => (
                            <div
                              key={item.Personnel_No}
                              className="px-4 py-2 flex"
                            >
                              {`#${item.Personnel_No} ${capitalizeFirstLetter(
                                item.First_Name
                              )} ${capitalizeFirstLetter(item.Last_Name)}`}
                              <div className="grow text-right">
                                <button
                                  onClick={() => handleRemove(item)}
                                  className="ml-2 text-red-500 hover:text-red-400 font-semibold"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      
                    </div>
                    
                  </div>
                  
                </div>
              </div>
              
            </div>

            {/* <div class="bg-white mb-4 flex text-center rounded-md py-4 text-sm">
                        <div className='w-full mx-5'>
                            <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Class ID</label>
                            <input  id = "Class" required form = "timesheet" onChange={handleInput} type="text" name="Class" placeholder="Class ID" className= {`   w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}/>
                        </div>
                        <div className='w-full mx-4'>
                            <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Date</label>
                            <input  id = "Date" required form = "timesheet" onChange={handleInput} type="date" name="Date" placeholder="Date" className={`  w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}/>
                        </div>
                        <div className='w-full mx-4'>
                            <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Duration</label>
                            <input  id = "Hours" required form = "timesheet" onChange={handleInput} type="text" name="Hours" placeholder="Hours" className={`w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}/>
                        </div>
                        <div className='w-full mx-4'>
                            <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Class Type</label>
                            <select  id = "Type" required form = "timesheet" onChange={handleInput} name="Type" className={` w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}>
                                <option value= {-1} disabled selected>Class Type</option>
                                <option value= {1}>Regular</option>
                                <option value= {2}>Substitute</option>
                                <option value= {3}>Volunteer</option>
                                <option value= {4}>AiBaiFu</option>
                        </select>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditSession;
