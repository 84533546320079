export default function ProjectLeft({ title, type, description, image }) {
  return (
    <div className="w-[80%] min-w-[350px] max-w-[790px] my-4 text-black md:flex mx-auto projectsmall">
      <img
        src={image}
        alt="Alpha House volunteering"
        className="md:w-[43%] w-[100%] object-cover object-center round shadow-md"
      />
      <div className="p-6 project pstyle">
        <h2>{title}</h2>
        <h3 className="project">{type}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
