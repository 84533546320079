import React from 'react';

const CircularProgressBar = ({ percentage }) => {
  const strokeDasharray = 455; // Circumference of the circle
  const strokeDashoffset = strokeDasharray - (strokeDasharray * percentage) / 100;

  return (
    <div className="relative w-40 h-40">
      <svg className="w-full h-full transform -rotate-90">
        <circle
          cx="50%"
          cy="50%"
          r="45%"
          strokeWidth="10%"
          stroke="#e5e7eb"
          fill="transparent"
        />
        <circle
          cx="50%"
          cy="50%"
          r="45%"
          strokeWidth="10%"
          stroke="currentColor"
          fill="transparent"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          className="text-[#f8740c] transition-stroke-dashoffset duration-500 ease-out"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-xl font-semibold text-gray-700">{percentage}%</span>
      </div>
    </div>
  );
};

export default CircularProgressBar;
