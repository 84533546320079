import React from "react";
import logo from "../assets/ignitewithword.png"
import "../Styles/AboutUs.css";

const About = () => {
    return(
        <div className = "max-w-[950px] mx-auto px-2 py-[25px] flex flex-col h-full text-center">
            <div className="h-[10vh]"></div>
            <div name = "About">
                <div className = " max-h-[100px] mx-auto px-2  pt-[30px] flex-col text-center">
                {/* <strong className="text-3xl font-semibold"> ABOUT US </strong> */}
                </div>
                
            </div>
        
            <div name = "main">
                <div className="flex flex-wrap gap-10 md:flex-nowrap py-11 pt-[50px] mx-[70px]">
                    {/* Elements you want to display */}
                    <div className="w-full md:pt-10 md:w-1/2">
                        <img src={logo} alt="Ignite Logo" className = "object-cover object-center flex-none lg:flex-auto"/>
                    </div>
                    <div className="w-full md:w-1.5/2 pt-6t">
                        <strong className= "text-xl font-bold"> IGNITE YOUTH CLUB </strong>
                        <p className = "leading-6 pt-6">Ignite Youth Club is a globally-oriented organization focused on sparking passions 
                            and igniting futures. Led by university students throughout North America, Ignite 
                            Education is committed to providing a better future for students from diverse backgrounds 
                            and age groups by enhancing their education through keynote speeches, classes, and competitions. 
                            Whether it be STEM or humanities, debate or coding, Ignite Education hopes to see students find their passions,
                             and burn bright, wherever those passions lead them.</p>
                    </div>
                    {/* Add more elements as needed */}
                 </div>
            </div>
            {/* <div name = "main">
                <div className="flex flex-col sm:flex-row ">
                    <div name="logo">
                        <div className="lg: py-[80px] text-center ">
                            <img src={logo} alt="Ignite Logo" className = "self-center lg:flex-auto md:flex-auto"/>
                        </div>
                    </div>
                    <div name="text">
                        <div className="mx-auto  pt-[70px] felx row text-center self-center"> 
                        <strong className= "text-2xl"> IGNITE YOUTH CLUB </strong>

                        <p>Ignite Youth Club is a globally-oriented organization focused on sparking passions 
                            and igniting futures. Led by university students throughout North America, Ignite 
                            Education is committed to providing a better future for students from diverse backgrounds 
                            and age groups by enhancing their education through keynote speeches, classes, and competitions. 
                            Whether it be STEM or humanities, debate or coding, Ignite Education hopes to see students find their passions,
                             and burn bright, wherever those passions lead them.</p>
                        </div>
                    </div>
                </div>       
            </div> */}

        </div>
    )
  }
  
  export default About;