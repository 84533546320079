import React from 'react';
import { useCallback, useEffect, useState } from 'react'

function Session({SessionNum, StartDate, EndDate, DueDate, Status, SubmissionDate, userStatus}) {

    const [userStatusDisplay, setUserStatus] = useState();
    const [colorValue, setColorValue] = useState();
    useEffect(() => {
        if(userStatus === 1 && SubmissionDate != null){
            setUserStatus("Submitted " + SubmissionDate);
        }else{
            setUserStatus("Due " + DueDate);
        };
        switch (Status) {
            case "Under Review":
            case "Upcoming":
                setColorValue("text-[#666666]");
              break;
            case "Completed":
                setColorValue("text-green-500");
              break;
            case "In Progress":
                setColorValue("text-orange-500");
              break;
        }
      });

  return (
    <div className = 'bg-gray-200 mx-6 mt-5 rounded-md flex hover:bg-gray-300 cursor-pointer'>
        <div className='w-[50%] text-left ml-6 my-4'>
            <p className='text-[#353535] text-xl font-bold'> Session {SessionNum}</p>
            <p className='text-xs my-1'>{StartDate} - {EndDate}</p>
        </div>
        <div className='w-[50%] text-right mr-6 my-4'>
            <p className={`text-xl font-bold ${colorValue}`}> {Status} </p>
            <p className='text-xs my-1'>{userStatusDisplay}</p>
        </div>
    </div>
  );
}

export default Session