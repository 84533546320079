import React from "react";

const ContactForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form inputs are temporarily disabled.");
  };

  return (
    <div>
        <div className="h-[10vh]"></div>
      <div className="max-w-md mx-auto p-6 bg-gray-100 rounded-lg shadow-md my-20 items-center">
        <h2 className="text-2xl font-bold mb-4 text-center">Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 font-bold mb-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="border-gray-300 bg-gray-200 rounded-md px-3 py-2 w-full"
              placeholder="Your Name"
              disabled
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="border-gray-300 bg-gray-200 rounded-md px-3 py-2 w-full"
              placeholder="Your Email"
              disabled
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-gray-700 font-bold mb-2"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              className="border-gray-300 bg-gray-200 rounded-md px-3 py-2 w-full"
              placeholder="Your Message"
              disabled
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-[#FF7605] hover:bg-[#ff7605d8] text-white font-bold py-2 px-4 rounded cursor-not-allowed"
            disabled
          >
            Submit
          </button>
        </form>
        <p className="text-red-600 text-center mt-4">
          Contact Us are temporarily disabled.
        </p>
        <p className="mt-4 text-center">
            Please Email us directly at <span className="font-bold">IgniteYouthClub@gmail.com</span>
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
