import React from "react";
import ProjectLeft from "../components/ProjectLeft";
import ProjectRight from "../components/ProjectRight";


const Projects = () => {
  return (
    <div name="Projects" className="w-full h-100%">
      <div className="max-w-[790px] mx-auto px-2 py-[25px] flex flex-col h-full text-center ">
        <div className="h-[75px]">  </div>
        <h1 className="text-2xl sm:text-3xl font-semibold">OUR PROJECTS</h1>
        <p>
          See what Ignite has been up to in the community since its creation in
          2018!
        </p>
      </div>
      <ul className="projectcont">
      <li>
          <ProjectLeft
            title={"Bottle Drive Fundraiser"}
            type={"Community"}
            description={
              "In July 2022, Ignite ran a bottle drive in Calgary to raise funds for our many projects and programs. 100% of the proceeds of this program are reinvested into the other ongoing projects Ignite is working on."
            }
            image = "/assets/Projects/BottleDrive.jpg"
          />
        </li>
        <li>
          <ProjectRight
            title={"AiBaiFu"}
            type={"Community"}
            description={
              "Since July of 2022, Ignite has had an ongoing partnership with Chinese organization AiBaiFu to provide free English tutoring to special needs children. Special thanks to our teachers who have graciously volunteered to dedicate their valuable time to make this program a success!"
            }
            image = "/assets/Projects/AiBaiFu.jpg"
          />
        </li>
        <li>
          <ProjectLeft
            title={"Alpha House Volunteering"}
            type={"Community"}
            description={
              "As COVID restrictions ease down in Alberta, Ignite Youth Club members returned to help out Alpha House, this time by packing lunches for their DOAP team!"
            }
            image = "/assets/Projects/AHvol.jpg"
          />
        </li>
        <li>
          <ProjectRight
            title={"Alpha House Donation"}
            type={"Community"}
            description={
              "Partnering with the Alpha House Society, a local homeless shelter, we organized a donation of over 140 items, amounting to around $1000. It was a great experience!"
            }
            image = "/assets/Projects/AHdono.jpg"
          />
        </li>
        <li>
          <ProjectLeft
            title={"Drop-In Center Donation"}
            type={"Community"}
            description={
              "Partnering with the Drop in Center, a local homeless shelter, we organized a donation of over 140 items, amounting to around $1000. We had lots of fun buying, packing, and delivering the items!"
            }
            image = "/assets/Projects/DIDono.jpg"
          />
        </li>
        <li>
          <ProjectRight
            title={"Western Canada High School Donation"}
            type={"Community"}
            description={
              "With the COVID-19 Pandemic impacting funding for high-school clubs. Ignite Youth Club Donated 1000 to Western Canada High School to support their Robotics Club! This money will lower costs for club members, making robotics education at WCHS more accessible."
            }
            image = "/assets/Projects/RoboDono.png"
          />
        </li>
        <li>
          <ProjectLeft
            title={"COVID-19 Ear Guard Initiative"}
            type={"Community"}
            description={
              "Recognizing the impact that COVID-19 has had on our hospitals and healthcare workers, we designed and 3D printed mask extenders. After over 150 hours of hard work, we delivered over 300 mask extenders to the Foothills Hospital."
            }
            image = "/assets/Projects/3dDono.jpg"
          />
        </li>
      </ul>
    </div>
  );
};

export default Projects;
