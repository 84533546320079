import React from 'react'
import { useParams } from 'react-router-dom'
import pic from "../assets/Default.jpg"
import { Link } from 'react-router-dom'
import ProfileMenu from '../components/ProfileMenu'
import ChinaTime from '../components/Clock'
import MorningHeader from '../components/MorningHeader'
import CircularProgressBar from '../components/PieChart';
import {useEffect, useState } from 'react'
import axios from 'axios'


export const Dashboard = () => {


    {/*Backend :O */}
    axios.defaults.withCredentials = true;

    const[userFirstName, setUserFirstName]=useState('ERROR');
    const[userLastName, setUserLastName]=useState('ERROR');
    const[userTotalHours,setUserTotalHours] = useState(0);
    const[userClassesTaught,setUserClassesTaught] = useState("WIP");
    const[userJoinDate,setUserJoinDate] = useState('ERROR'); 
    const[userPosition,setUserPosition] = useState('ERROR'); 
    const[userSessionHour,setUserSessionHour] = useState('ERROR');
    const[userLatestClass,setUserLatestClass] = useState('');
    const[sessionStats,setSessionStats] = useState('ERROR');
    const[currentSeshID,setSeshID] = useState(0);
    const[percentage,setPercentage] = useState(0);
    

    const [availableSessions, setAvailableSessions] = useState([]);
    
    useEffect(()=>{

        axios.get(process.env.REACT_APP_Apiurl + "login").then((res) => {
            if (!res.data.Authorized) {
              window.location.href = process.env.REACT_APP_App_Url;
            }
        });

        axios.get(process.env.REACT_APP_Apiurl + 'user/Info')
        .then((res) => {
            setUserFirstName(res.data.First_Name); 
            setUserLastName(res.data.Last_Name); 
            setUserTotalHours(res.data.Total_Hours);
            setUserJoinDate(res.data.Join_Date);
            setUserPosition(res.data.Position);

        })
        .catch(error => {
            console.error('Error:', error);
        },[]);

        axios.get(process.env.REACT_APP_Apiurl + 'user/hours')
        .then((res) => {
            // Handle successful response
            setUserSessionHour(res.data.Volunteerhours);
            setSeshID(res.data.SessionNo);
           
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
        },[]);

        axios.get(process.env.REACT_APP_Apiurl + 'user/status')
        .then((res) => {
            // Handle successful response
            setSessionStats(res.data.Session_Status); 
            setPercentage(calculateElapsedPercentage(res.data.Start, res.data.End));
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
            setSessionStats("INACTIVE"); 
            setPercentage(0);
        },[]);

        const calculateElapsedPercentage = (startDateStr, endDateStr) => {
            const now = new Date();
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
          
            if (now >= endDate) {
              return 100; // Current date is after the end date
            }
            
            if (now <= startDate) {
              return 0; // Current date is before the start date
            }
          
            // Calculate percentage
            const totalDuration = endDate - startDate;
            const elapsedDuration = now - startDate;
            const percentageElapsed = (elapsedDuration / totalDuration) * 100;
          
            return Math.round(percentageElapsed); // Round to the nearest integer
          };




        const populateSubmissions = () => {
            axios.get(process.env.REACT_APP_Apiurl + "user/submissions/"+ currentSeshID)
            .then((res) => {
                setAvailableSessions(res.data);
                console.log(res.data)
            })
          }
        populateSubmissions(); 
    },[currentSeshID]);

    const capitalizeFirstLetter = ([first, ...rest]) => {
        return first.toUpperCase() + rest.join('');
    };

  return (
    <div className = "bg-gray-200 w-[100vw] h-[100vh] flex flex-row">
        
        <div className='w-[15%] h-[100vh]'>
            <ProfileMenu/>
        </div>

        
        <div className="flex-col w-[85%] h-[100vh] border-x-[2vw]">
            {/*Name + China Local Time*/} 
            {/*constants: padding top - 4vh
                          padding left - 3vw
            */} 
            <div className = " w-full  h-[5%] mt-[4vh] flex md:space-x-4 ">
                <MorningHeader/>
                <div className = "lg:grow rounded-md invisible md:visible bg-white shadow-sm text-left md:flex items-center space-x-1">
                    <ChinaTime/>
                </div>
            </div>

            {/*Profile Pic + Session overview + status*/} 
            <div className = "w-full h-[35%] mt-[3vh] flex flex-row  space-x-[3vh]">
                <div className = "w-[90vw] md:w-[20vw] h-full rounded-md flex flex-col shadow-sm bg-white jusitfy-center items-center text-center content-center ">
                    <img className = "w-[12vw] max-w-[200px] mt-[4vh] aspect-square rounded-full "src={pic} alt  = "default"></img>
                    <p className = "text-lg font-bold mt-2">{capitalizeFirstLetter(userFirstName)} {capitalizeFirstLetter(userLastName)} </p>
                    <p className = "font-md">{userPosition}</p>
                </div>

                <div className = "invinsible md:w-[40vw] h-full rounded-md flex flex-col shadow-sm bg-white jusitfy-center items-center text-center content-center ">
                    <p className = "font-semibold mt-[2vh]">Current Session Overview </p>
                    <div className = "grid grid-cols-2 grid-flow-row w-full border-white border-x-[3vw] mt-8 gap-x-7 gap-y-6">
                        <div className = "flex gap-2">
                            <div className = "aspect-square rounded-md justify-center items-center flex w-12 bg-orange-300 shadow-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8">
                                <path fillRule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" clipRule="evenodd" />
                                <path d="M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z" />
                            </svg>

                            </div>
                            <div className = "flex flex-col text-left">
                                <p className = "text-sm text-slate-500 ">Session No.</p>
                                <p className = " text-slate-700 ">{currentSeshID}</p>
                            </div>           
                        </div>

                        <div className = "flex gap-2">
                            <div className = "aspect-square rounded-md w-12 items-center justify-center flex bg-orange-300 shadow-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8">
                                    <path d="M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z" />
                                    <path d="M13.06 15.473a48.45 48.45 0 0 1 7.666-3.282c.134 1.414.22 2.843.255 4.284a.75.75 0 0 1-.46.711 47.87 47.87 0 0 0-8.105 4.342.75.75 0 0 1-.832 0 47.87 47.87 0 0 0-8.104-4.342.75.75 0 0 1-.461-.71c.035-1.442.121-2.87.255-4.286.921.304 1.83.634 2.726.99v1.27a1.5 1.5 0 0 0-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.66a6.727 6.727 0 0 0 .551-1.607 1.5 1.5 0 0 0 .14-2.67v-.645a48.549 48.549 0 0 1 3.44 1.667 2.25 2.25 0 0 0 2.12 0Z" />
                                    <path d="M4.462 19.462c.42-.419.753-.89 1-1.395.453.214.902.435 1.347.662a6.742 6.742 0 0 1-1.286 1.794.75.75 0 0 1-1.06-1.06Z" />
                                </svg>


                            </div>
                            <div className = "flex flex-col text-left">
                                <p className = "text-sm text-slate-500 ">Latest Class ID.</p>
                                <p className = " text-slate-700 ">WIP</p>
                            </div>           
                        </div>

                        <div className = "flex gap-2">
                            <div className = "aspect-square items-center flex justify-center rounded-md w-12 bg-orange-300 shadow-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z" clipRule="evenodd" />
                            </svg>


                            </div>
                            <div className = "flex flex-col text-left">
                                <p className = "text-sm text-slate-500 ">Hours logged</p>
                                <p className = " text-slate-700 ">{userSessionHour}</p>
                            </div>           
                        </div>

                        {/* <div className = "flex gap-2">
                            <div className = "aspect-square rounded-md w-12 bg-orange-300 shadow-sm"></div>
                            <div className = "flex flex-col text-left">
                                <p className = "text-sm text-slate-500 ">Session No.</p>
                                <p className = " text-slate-700 ">actual number</p>
                            </div>           
                        </div> */}
                        
                       
                    </div>
                </div>


                <div className = {`invinsible md:grow h-full rounded-md flex flex-col shadow-sm jusitfy-center items-center text-center content-center bg-white`}>
                    <p className = "font-semibold text-lg mt-[2vh]">Session {currentSeshID} Status </p>
                    <div className = {`align-bottom flex flex-col text-lg font-semibold justify-center h-[65%] mx-5`}>
                        <CircularProgressBar percentage={percentage} />
                    </div>
                    <p className = "text-lg font-semibold text-slate-500">{sessionStats}</p>
                   
                </div>

            </div>


            {/*LifeTime stats + Session Timesheet*/} 
            <div className = "h-[47%] mt-[3vh] flex flex-row  space-x-[3vh]">
                <div className = "w-[20vw] h-full rounded-md flex flex-col shadow-sm bg-white jusitfy-center items-center text-center content-center">
                    <p className = "font-semibold mt-[2vh]">Lifetime Stats </p>

                    <div className = "mt-[5vh] flex items-stretch border-white border-x-[2vw] space-x-5">
                        <div className = "aspect-square rounded-md w-12 bg-orange-300 shadow-sm flex justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z" clipRule="evenodd" />
                            </svg>
                        </div>
                            <div className = "flex-col text-left">
                                <p className = "text-slate-500 text-sm">Hours Worked</p>
                                <div className = "text-lg flex gap-2 items-end">{userTotalHours}
                                    <p className = "text-slate-500 text-sm">hrs</p>
                                </div>
                            </div>
                    </div>

                    <div className = "mt-[5vh] flex items-stretch border-white border-x-[2vw] space-x-5">
                        <div className = "aspect-square rounded-md w-12 bg-orange-300 shadow-sm justify-center items-center flex">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8 ">
                                <path fillRule="evenodd" d="M10.5 3.798v5.02a3 3 0 0 1-.879 2.121l-2.377 2.377a9.845 9.845 0 0 1 5.091 1.013 8.315 8.315 0 0 0 5.713.636l.285-.071-3.954-3.955a3 3 0 0 1-.879-2.121v-5.02a23.614 23.614 0 0 0-3 0Zm4.5.138a.75.75 0 0 0 .093-1.495A24.837 24.837 0 0 0 12 2.25a25.048 25.048 0 0 0-3.093.191A.75.75 0 0 0 9 3.936v4.882a1.5 1.5 0 0 1-.44 1.06l-6.293 6.294c-1.62 1.621-.903 4.475 1.471 4.88 2.686.46 5.447.698 8.262.698 2.816 0 5.576-.239 8.262-.697 2.373-.406 3.092-3.26 1.47-4.881L15.44 9.879A1.5 1.5 0 0 1 15 8.818V3.936Z" clipRule="evenodd" />
                            </svg>

                        </div>
                            <div className = "flex-col text-left">
                                <p className = "text-slate-500 text-sm">Classes Taught</p>
                                <div className = "text-lg flex gap-2 items-end">{userClassesTaught}
                                    <p className = "text-slate-500 text-sm">classes</p>
                                </div>
                            </div>
                    </div>

                    <p className = "mt-[4vh] font-bold text-sm">Member Since:</p>
                    <p className = "font-semibold text-slate-700 ">{userJoinDate}</p>

                </div>

                <div className = "invinsible md:grow h-full rounded-md flex flex-col w-auto shadow-sm bg-white jusitfy-center items-center text-center content-center ">
                    <p className = "font-semibold mt-[2vh]">Session {currentSeshID} Timesheet Preview </p>
                    <div className = "w-full mt-4">

                    {availableSessions.length === 0 ? (
                                <div className='text-slate-700 font-semibold mx-4 py-5 rounded-lg h-full flex items-center justify-center bg-gray-200'>
                                No timesheet entries to show
                              </div>
                              
                            ) : (
                    <table class=" w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="w-full sticky top-0 text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="pl-6 py-3">
                                    No.
                                </th>
                                <th scope="col"b class="pl-4 pr-6 py-3">
                                    Class ID
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Class Type
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Duration
                                </th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            
                                {availableSessions.map((item, i) => ( 

                                <tr key={item.Submission_No} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td class="pl-6 py-4">
                                        {availableSessions.length-i}
                                    </td>
                                    <th scope="row" class="pl-4 pr-6 py-4 font-medium text-gray-900 dark:text-white">
                                        {item.Class_No}
                                    </th>
                                    <td class="px-6 py-4">
                                        {item.Date}
                                    </td>
                                    <td class="px-6 py-4">
                                        {item.Class_Type}
                                    </td>
                                    <td class="px-6 py-4">
                                        {item.Hours} {item.Hours === 1 ? 'Hour' : 'Hours'}
                                    </td>
                                    <td class=" py-4 flex ">
                                    </td>
                                </tr>
                                
                                ))}
                            

                        </tbody>
                    </table>
                        )}

                    </div>
                </div>

            </div>
            
            
        </div>

    </div>
  )
}




export default Dashboard