import React ,{useState,useEffect} from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';

function MorningHeader() {
axios.defaults.withCredentials = true;

const [chinaTime, setChinaTime] = useState(null);
const [greetMessage, setGreetMessage] = useState(""); 

  useEffect(() => {
    // Function to fetch and update China time
    const fetchAndSetChinaTime = async () => {
      try {
        const response = await fetch('http://worldtimeapi.org/api/timezone/'+timeZone());
        const data = await response.json();
        // Extract the time from the response
        const time = data.datetime;
        const formattedTime = formatTime(time);
        setChinaTime(formattedTime);
        //let x = formattedTime; 

// changne greetings 
       
        let x = extractHour(formatTime(time)); 

        if (x>1700){
          setGreetMessage("Good Evening ");
        }
        else if (x>1200){
          setGreetMessage("Good Afternoon ");
        }
       else{setGreetMessage("Good Morning ")}
      
      } catch (error) {
        console.error('Error fetching China time:', error);
      }
    };

   
    // Initial fetch on component mount
    fetchAndSetChinaTime();
  

    // Set interval to fetch and update China time every minute
    const intervalId = setInterval(fetchAndSetChinaTime, 1000); // Fetch every minute

    
  
    // Cleanup function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once on mount

  const extractHour = (time) => {
    let hour = null; 
    if (time != null){
      hour = time.replace(':','');
    }
    return hour;
};

const timeZone = () => {
  return DateTime.local().zoneName; 
}

  const formatTime = (time) => {
    const dateTime = DateTime.fromISO(time, { zone: timeZone() });
    return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
  };

    const [Name, setName] = useState(null);
    
    const capitalizeFirstLetter = ([first, ...rest]) => {
        return first.toUpperCase() + rest.join('');
    };

    

    useEffect(() => {
        axios.get(process.env.REACT_APP_Apiurl + "user/name")
          .then((res) => {
            setName(capitalizeFirstLetter(res.data.First_Name));
          })
          .catch(error => {
            // Handle error
            console.error('Header_Error:', error);
        });
      })

      

      return (
        <div className = "w-[90%] lg:w-[60%] rounded-md  bg-white text-left shadow-sm flex items-center space-x-1 ">
                    <p className = "font-thin ml-2">{greetMessage}</p>
                    <p className = "font-semibold">{Name}!</p>
        </div>
      );


}

export default MorningHeader