import React from 'react';
import "../Styles/Footer.css";

function Footer() {
  return (
    <div className= "footer">

        <p className='text-white'> &copy; 2024 Igniteyouthclub.com. All rights reserved</p>

        </div>
  );
}

export default Footer