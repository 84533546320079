import { Link } from 'react-router-dom'
import logo from "../assets/Ignite-Logo.png"
import React, { useEffect, useState } from 'react'
import axios from 'axios'

function Footer({}) {
    axios.defaults.withCredentials = true;

    const [admin, setAdmin] = useState(false);
    const handleLogout = () => {
        localStorage.setItem("token", '');
        axios.get(process.env.REACT_APP_Apiurl + "logout")
        .then(res=> {
            window.location.href = process.env.REACT_APP_App_Url;
        })
        .catch(error => 
            console.log(error));
      }

      useEffect(() => {

        axios.get(process.env.REACT_APP_Apiurl + "login")
            .then((res) => {
                setAdmin(res.data.admin);
            })
      },[]);
  return (
    <div className="fixed min-w-[150px] w-[15vw] h-[100vh] bg-white flex-col text-center">
            <div className="w-full h-[18%] flex items-center justify-center">
                <Link to='/'>
                    <img className = "max-w h-auto size-9"src={logo} alt  = "Ignite Logo"></img>
                </Link>
            </div>

            {admin ? (
                <div>
                    <hr className="w-full bg-gray-400 flex"></hr>

                    <div className = "my-[5vh] text-neutral-600 text-md">
                        <ul className='nav space-y-5'>
                            <li>
                                <Link className = " hover:font-bold" to='../Admin/Sessions'>Sessions</Link>
                            </li>
                            <li>
                                <Link className = " hover:font-bold" to='../Admin/Users'>Users</Link>
                            </li>
                            <li>
                                <Link className = " hover:font-bold" >DISABLED</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            ):(
                <div></div>
            )}

            <hr className="w-full bg-gray-400 flex"></hr>

            <div className = "my-[5vh] text-neutral-600 text-md">
                <ul className='nav space-y-5  '>
                        <li>
                            <Link className = " hover:font-bold" to = '../Profile/Dashboard' aria-disabled>Dashboard</Link>
                        </li>
                        <li>
                            <Link className = " hover:font-bold" to = '../Profile/Sessions'>Timesheets</Link>
                        </li>
                        <li>
                            <Link className = " hover:font-bold" to = '../Profile/Account'>My Account</Link>
                        </li>
                    </ul>
            </div>

            <div className=' w-full absolute bottom-[5%]'>
                <button onClick = {handleLogout} className="bg-[#f8740c] px-1 text-white text-sm font-semibold hover:bg-[#ff913c] focus:outline-none focus:ring focus:border-blue-300 w-[50%] rounded-full">Log Out</button>

            </div>

        </div>
  );
}

export default Footer