import React from 'react';
import { useCallback, useEffect, useState } from 'react'

function TimesheetSubmission({firstName, lastName, payRate, status, submissionDate, volunteerTotal, paidTotal, dueDate}) {

    const [statusMessage, setStatus] = useState("");
    const [pay, setPay] = useState(0.0);

    useEffect(() => {
        if(status === 1){
          setStatus("Submitted: " + submissionDate);
        }else if(status === 0){
          setStatus("In Progress");
        }

        setPay(payRate * paidTotal);


      });

  return (
    <div className = 'hover:bg-[#dfdede] bg-[#EDECEC] mx-6 mt-5 rounded-md flex-col cursor-pointer outline outline-1 outline-[#D9D9D9]'>
        <div className='w-[100%] text-left bg-white flex py-3 px-4'>
            <div className='flex grow items-center'>
              <p className='pr-3 font-semibold text-lg text-[#353535]'>{firstName} {lastName}</p>
              <p className='text-sm text-[#666666]'> {statusMessage}</p>
            </div>
            <div className='grow text-right'>
              <p className='font-semibold text-lg text-[#353535]'>{status ===1 ? ("Pay: $"+ pay):("Not Submitted")}</p>
            </div>
        </div>
        <div className='w-[100%] text-left flex py-3 px-4'>

              {
                status === 1 ? (
                  <div className='flex grow'>
                  <p className='text-sm text-[#666666] mr-1'>Total Paid Hrs: </p>
              <p className='text-sm text-[#666666] mr-3 font-bold'> {paidTotal} Hours </p>
              <p className='text-sm text-[#666666] mr-1' >Total Volunteer Hrs</p>
              <p className='text-sm text-[#666666] mr-3 font-bold'> {volunteerTotal} Hours </p>
                  </div>
                ):(
                  <div className='flex grow'>
                  <p className='text-sm text-[#666666]'>
                    Not Submitted
                  </p>
                  </div>
                )
              }
              
            <div className='grow text-right'>
              <p className='text-sm text-[#666666] font-bold'>${payRate}/Hour</p>
            </div>
        </div>
    </div>
  );
}

export default TimesheetSubmission