import Header from "./components/Header";
import Footer from './components/Footer';
import Team from "./pages/Our-Team";
import Home from "./pages/Home";
import AboutUs from "./pages/About-Us";
import Login from "./pages/Log-in"; 
import Signup from "./pages/Sign-up"; 

import { Routes, Route, useLocation } from 'react-router-dom';
import Projects from "./pages/Projects";
import Program from "./pages/Programs";
import UserSessions from "./pages/UserSessions";
import TimeSheet from "./pages/TimeSheet";
import Dashboard from "./pages/dashboard";
import AdminSesh from "./pages/Admin-Sessions"; 
import Users from "./pages/Admin-Users"; 
import AdminSessionView from "./pages/Admin-SessionView"; 
import MyAccount from "./pages/My-Account";
import CreateSession from "./pages/CreateSession";
import EditSession from "./pages/Admin-EditSession";
import PageNotFound from "./pages/pageNotFound";
import AdminTimeSheet from "./pages/admin-timesheetPreview";
import ContactForm from './pages/contactus';
import AdminEditMyAccount from './pages/Admin-EditUser';

function App() {

  const location = useLocation();

  const isProfileOrAdminPage = () => {
    const profilePaths = ['/Member-profile', '/Profile/Sessions', '/Profile/Sessions/:id', '/Profile/Dashboard','/Profile/Account'];
    const adminPaths = ['/Admin/Sessions', '/Admin/Session/:id', '/Admin/Users', '/Admin/Sessions/Create'];
    
    const allPaths = [...profilePaths, ...adminPaths];

    return allPaths.some(path => location.pathname.startsWith(path.split(':')[0])); // Handling dynamic segments
  };

  const showfooter = () => {
    const profilePaths = ['/Program'];
    
    const allPaths = [...profilePaths];

    return allPaths.some(path => location.pathname.startsWith(path.split(':')[0])); // Handling dynamic segments
  };

  return (
    <div>
        {!isProfileOrAdminPage() && <Header/>}
      <Routes>
        <Route path = '/' element={<Home/>}/>
        <Route path = '/Our-Team' element={<Team/>}/>
        <Route path = '/Projects' element={<Projects/>}/>
        <Route path = '/About-Us' element={<AboutUs/>}/>
        <Route path = '/Home' element={<Team/>}/>
        <Route path = '/Log-in' element={<Login/>}/>
        <Route path = '/Sign-up' element={<Signup/>}/>
        <Route path = '/Contact-Us' element={<ContactForm/>}/>
        <Route path = '/Programs' element={<Program/>}/>
        <Route path = '/Profile/Sessions' element={<UserSessions/>}/>
        <Route path = '/Profile/Sessions/:id' element={<TimeSheet/>}/>
        <Route path = '/Profile/Dashboard' element={<Dashboard/>}/>
        <Route path = '/Admin/Sessions' element = {<AdminSesh/>}/>
        <Route path = '/Admin/Session/:id' element = {<AdminSessionView/>}/>
        <Route path = '/Admin/Users' element = {<Users/>}/>
        <Route path = '/Admin/Sessions/Create' element = {<CreateSession/>}/>
        <Route path = '/Admin/Session/Edit/:id' element = {<EditSession/>}/>
        <Route path = '/Admin/Session/View/:id/:personnelNum' element = {<AdminTimeSheet/>}/>
        <Route path = '/Profile/Account' element = {<MyAccount/>}/>
        <Route path = '/Admin/Users/:PersonnelNo' element = {<AdminEditMyAccount/>}/>
        <Route path = "*" element = {<PageNotFound/>}/>
      </Routes>
      {(!isProfileOrAdminPage() && !showfooter()) && <Footer/>}
    </div>
  );
}

export default App;
