import React from "react";
import TeamMember from "../components/TeamMember";

const Team = () => {
  return (
    <div name="Team" className="w-full h-100%">
      <div className="max-w-[1200px] mx-auto px-2 py-[100px] flex flex-col h-full text-center">
        <h1 className="text-2xl sm:text-3xl font-semibold">
          FOUNDERS OF IGNITE
        </h1>
        <hr className="w-20 mx-auto border-b-2 border-[#FF7605] mb-8" />
        <ul className="text-center Our-Team">
          <li>
            <TeamMember
              name={"Kevin Yao"}
              photo={"/assets/profile/KevinYao.jpg"} 
              position={"Co-Founder"}
            />
          </li>
          <li>
            <TeamMember
              name={"Eric Wen"}
              photo={"/assets/profile/EricWen.jpg"} 
              position={"Co-Founder"}
            />
          </li>
          <li>
            <TeamMember
              name={"Mason Li"}
              photo={"/assets/profile/MasonLi.jpg"} 
              position={"Co-Founder"}
            />
          </li>
          <li>
            <TeamMember
              name={"Brendon Lu"}
              photo={"/assets/profile/BrendonLu.jpg"} 
              position={"Co-Founder"}
            />
          </li>
        </ul>

        <h1 className="text-2xl sm:text-3xl font-semibold">TEACHERS</h1>
        <hr className="w-20 mx-auto border-b-2 border-[#FF7605] mb-8" />
        <ul className="text-center Our-Team">
          <li>
            <TeamMember 
            name={"Kristina Lu"} 
            photo={"/assets/profile/KristinaLu.jpg"} 
            position={"Teacher"} 
            />
          </li>
          <li>
            <TeamMember 
            name={"Eirena Wen"} 
            photo={"/assets/profile/EirenaWen.jpg"} 
            position={"Teacher"} 
            />
          </li>
          <li>
            <TeamMember
              name={"Ivy Shi"}
              photo={"/assets/profile/IvyShi.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Brianne Yu"}
              photo={"/assets/profile/BrianneYu.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Crystal Lam"}
              photo={"/assets/profile/CrystalLam.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Gracia Xia"}
              photo={"/assets/profile/GraciaXia.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember 
            name={"Josh Li"} 
            photo={"/assets/profile/JoshLi.jpg"} 
            position={"Teacher"} 
            />
          </li>
          <li>
            <TeamMember
              name={"Michael Feng"}
              photo={"/assets/profile/MichaelFeng.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Sofia Han"}
              photo={"/assets/profile/SophiaHan.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Oliver Geng"}
              photo={"/assets/profile/OliverGeng.png"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Jessamine Li"}
              photo={"/assets/profile/JessamineLi.jpeg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Anik Xiong"}
              photo={"/assets/profile/Anik.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Jamie Wang"}
              photo={"/assets/profile/Jamie.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Sophie Wu"}
              photo={"/assets/profile/SophieWu.jpg"} 
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Brian Wei"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Melanie Feng"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Rhona Chen"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Bruce Lin"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Ambrose Chen"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Amie Yao"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Carina Shen"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Cosmina Tan"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Gavin Zhu"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember
              name={"Jazmine Su"}
              photo={"/assets/profile/IgniteNoProfile.png"}
              position={"Teacher"}
            />
          </li>
          <li>
            <TeamMember name={"Max Li"} photo={"/assets/profile/IgniteNoProfile.png"} position={"Teacher"} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Team;
