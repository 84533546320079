import React from 'react';
import axios from 'axios';
import ProfileMenu from '../components/ProfileMenu'
import {useEffect, useState } from 'react'
import Chinaclock from '../components/Clock'

export const Users = () => {

  axios.defaults.withCredentials = true;
  const [personnelArray, setPersonnelArray] = useState([]);

  const capitalizeFirstLetter = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join('');
  };

  useEffect(() => {

    axios.get(process.env.REACT_APP_Apiurl + "login")
        .then((res) => {
            if(!res.data.admin){
            console.log(res)
            window.location.href = process.env.REACT_APP_App_Url
            }
        })

        axios.get(process.env.REACT_APP_Apiurl + "admin/all/personnel")
        .then((res) => {
            console.log(res);
            setPersonnelArray(res.data.personnel);
        })
  },[]);


  const handleItemClick = (Num) => {
    var targetUrl = "../Admin/Users/" + Num;
    window.location.href = targetUrl;
    };

  return (
    <div className = "bg-gray-200 w-[100vw] h-[100vh] flex flex-row">
        
        <div className='w-[15%] h-[100vh]'>
            <ProfileMenu/>
        </div>

        <div className="flex-col w-[85%] border-x-[2vw]">
            <div className = " w-full mt-[4vh] flex md:space-x-4">
                <div className = "py-2 min-w-[60%] rounded-md bg-white text-left flex items-center space-x-1 ">
                    <p className = "font-semibold ml-4">Personnel</p>
                </div>
                <div className = "w-full rounded-md visible bg-white text-right flex items-center">
                    <p className = "font-semibold ml-3">Total Users: {personnelArray.length}</p>
                </div>
            </div>
            <div className='w-full'>
                <div class=" shadow-md rounded-md bg-white h-[85vh] overflow-auto my-4">
                    <table class=" w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="sticky top-0 text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="pl-6 py-3">
                                    Personnel No.
                                </th>
                                <th scope="col"b class="pl-4 pr-6 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Admin
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Position
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Options
                                </th>
                            </tr>
                        </thead>
                        
                        <tbody>
                        {personnelArray === null ? (
                                <div>Error: Failed to fetch data</div>
                            ) : (
                                /* Map over the array of fetched items */
                                personnelArray.map((item, i) => ( 

                                <tr key={item.Personnel_No} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td class="pl-6 py-4">
                                        {item.Personnel_No}
                                    </td>
                                    <th scope="row" class="pl-4 pr-6 py-4 font-medium text-gray-900 dark:text-white">
                                        {capitalizeFirstLetter(item.First_Name) + " " + capitalizeFirstLetter(item.Last_Name)}
                                    </th>
                                    <td class="px-6 py-4">
                                        {item.Payout_Email}
                                    </td>
                                    <td class="px-6 py-4">
                                        {item.Is_Admin === 1 ? "True" : "False"}
                                    </td>
                                    <td class="px-6 py-4">
                                        {item.Position}
                                    </td>
                                    <td class=" py-4 flex ">
                                        
                                        <div className='text-center grow ml-1'>
                                          <button onClick = {() => handleItemClick(item.Personnel_No)} class="font-medium text-orange-500 hover:text-orange-400 mr-4">Edit</button>
                                        </div>
                                    </td>
                                </tr>
                                
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
       
    </div>
  )
}
export default Users