import React from 'react';
import axios from 'axios';
import ProfileMenu from '../components/ProfileMenu'
import TimesheetSubmission from '../components/TimesheetSubmission'
import {useEffect, useState } from 'react'
import { useParams,Link } from 'react-router-dom'

export const AdminSessionView = () => {

  axios.defaults.withCredentials = true;

    const [timeSheets, settimeSheets] = useState([]);
    const [sessionData, setSessionData] = useState({
        Start:"",
        End: "",
        Due: "",
        Session_status: "",
        Is_Visible: null
    })
    const [Visibility, setVisibility] = useState("Error");
    const [colorValue, setColorValue] = useState();
    const [statusColor, setStatusColor] = useState();
    const [volunteerTotal, setvolunteerTotal] = useState(0.0);
    const [paidTotal, setpaidTotal] = useState(0.0);

  const{id} = useParams();

  useEffect(() => {

    axios.get(process.env.REACT_APP_Apiurl + "login")
        .then((res) => {
            if(!res.data.admin){
            console.log(res)
            window.location.href = process.env.REACT_APP_App_Url
            }
        })

    axios.get(process.env.REACT_APP_Apiurl + "session/timeSheets/"+ id)
        .then((res) => {
            if(!res.data.Auth){
                window.location.href = process.env.REACT_APP_App_Url
            }else{
                settimeSheets(res.data.timeSheets)
                let totalvolunteer = 0.0;
                let totalpaid = 0.0;
                console.log(res.data);
                for(const timesheet of res.data.timeSheets){
                    totalvolunteer += timesheet.Volunteerhours;
                    totalpaid += timesheet.Paidhours;
                }
                setvolunteerTotal(totalvolunteer);
                setpaidTotal(totalpaid);
            }
            
        })

    axios.get(process.env.REACT_APP_Apiurl + "admin/session/"+ id)
        .then((res) => {
            if(!res.data.Auth){
                window.location.href = process.env.REACT_APP_App_Url
            }else{
                setSessionData(res.data.session[0])
            }
        })
  },[]);

  useEffect(() => {
    if (sessionData) {
        setColorValue("text-red-500");
      if (sessionData.Is_Visible) {
        setVisibility("Active");
        setColorValue("text-green-500");
      } else if (sessionData.Is_Visible === 0) {
        setVisibility("Hidden");
      }
      setStatusColor("text-red-500");
      switch (sessionData.Session_Status) {
        case "Under Review":
        case "Upcoming":
            setStatusColor("text-[#666666]");
          break;
        case "Completed":
            setStatusColor("text-green-500");
          break;
        case "In Progress":
            setStatusColor("text-orange-500");
          break;
        }
        
    }
  }, [sessionData]);


  const handleItemClick = (Num) => {
    var targetUrl = "../Session/View/" + id + "/" + Num;
    window.location.href = targetUrl;
    };

  return (
    <div className = "bg-gray-200 w-[100vw] h-[100vh] flex flex-row">
        <div className='w-[15%] h-[100vh]'>
            <ProfileMenu/>
        </div>
        <div className="flex-col w-[85%] h-[100vh] border-x-[2vw]">
            
            <div className = " mt-[4vh] flex md:space-x-4 sm:grow">
                <div className = " py-2 grow min-w-[60%] rounded-md bg-white text-left flex items-center space-x-1 ">
                    <p className = "font-semibold ml-4">Session {id} Admin View </p>
                    <div className="grow text-right">
                        <Link
                            to={`../Admin/Session/Edit/${id}`}
                            className="my-2 rounded-md bg-orange-500 cursor-pointer text-white hover:bg-orange-400 mr-4 py-1 px-3 font-semibold"
                        >
                            Edit Session
                        </Link>
                    </div>
                </div>
                <div  className = " grow rounded-md bg-white text-center flex items-center space-x-1 ">
                    <p className = "font-semibold ml-4 ">Session Total Pay: </p>
                    <p className = "font-semibold  ">WIP</p>
                </div>
            </div>

            <div className='w-full h-[87vh] flex flex-col'>
                {/*Session Info*/}
                <div className='flex py-4 justify-stretch'>
                    <div className='rounded-md bg-white p-3 text-center grow max-w-[300px]'>
                        <p className='text-lg font-semibold'>{sessionData.Start} - {sessionData.End}</p>
                        <div className=' flex mx-3 mt-1 justify-center '>
                            <p className='text-[#666666] text-sm'>Session Duration</p>
                        </div>
                    </div>
                    <div className='rounded-md bg-white p-3 text-center ml-4 grow'>
                        <p className={`text-lg font-medium ${statusColor}`}>{sessionData.Session_Status}</p>
                        <p className='text-[#666666] text-sm'>Status</p>
                    </div>
                    <div className='rounded-md bg-white p-3 text-center ml-4 grow'>
                        <p className={`text-lg font-medium ${colorValue}`}>{Visibility}</p>
                        <p className='text-[#666666] text-sm'>Visibility</p>
                    </div>
                    <div className='rounded-md bg-white p-3 text-center ml-4 grow'>
                        <p className='text-lg font-medium'>{paidTotal}</p>
                        <p className='text-[#666666] text-sm'>Total Paid Hours</p>
                    </div>
                    <div className='rounded-md bg-white p-3 text-center ml-4 grow'>
                        <p className='text-lg font-medium'>{volunteerTotal}</p>
                        <p className='text-[#666666] text-sm'>Total Volunteer Hours</p>
                    </div>
                </div>

                {/*timesheet*/}
                    
                <div className =" shadow-md rounded-md bg-white h-[66vh] overflow-auto">
                <div className =' max-h-[800px] w-full my-4 rounded-lg pb-6 bg-white text-center relative'>
                    <p className='text-xl pt-[1vh] font-bold'>Time sheets</p>
                    {timeSheets === null ? (
                                <div>Error: Failed to fetch data</div>
                            ) : (
                                /* Map over the array of fetched items */
                                timeSheets.map((item) => ( 
                                <div key={item.PersonnelNo} onClick = {() => handleItemClick(item.PersonnelNo)}>
                                    <TimesheetSubmission 
                                    firstName = {item.First_Name}
                                    lastName = {item.Last_Name}
                                    payRate = {item.Pay_Rate}
                                    status = {item.Submitted}
                                    submissionDate = {item.Submission_Date}
                                    volunteerTotal = {item.Volunteerhours}
                                    paidTotal= {item.Paidhours}
                                    dueDate= {sessionData.Due}/>
                                </div>
                                ))
                            )}
                </div>
                </div>
            </div>
          
        </div>
       
    </div>
  )
}
export default AdminSessionView