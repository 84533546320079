import React, { useState, useEffect } from "react";

function SearchInput({ onSelect, selectedItems, personnelArray }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(personnelArray);

  useEffect(() => {
    // Update search results whenever personnelArray or selectedItems change
    if (searchTerm) {
      let filteredResults;

      const selectedPersonnelNos = selectedItems.map(
        (item) => item.Personnel_No
      );

      if (!isNaN(searchTerm)) {
        // Check if the input is a number
        filteredResults = personnelArray
          .filter((item) => item.Personnel_No.toString().includes(searchTerm))
          .filter((item) => !selectedPersonnelNos.includes(item.Personnel_No)); // Filter out selected items
      } else {
        // If the input is a string
        filteredResults = personnelArray
          .filter((item) =>
            `${item.First_Name.toLowerCase()} ${item.Last_Name.toLowerCase()}`.includes(
              searchTerm.toLowerCase()
            )
          )
          .filter((item) => !selectedPersonnelNos.includes(item.Personnel_No)); // Filter out selected items
      }
      setSearchResults(filteredResults);
    } else {
      // When there is no search term, show all personnel excluding selected ones
      const selectedPersonnelNos = selectedItems.map(
        (item) => item.Personnel_No
      );
      setSearchResults(
        personnelArray.filter(
          (item) => !selectedPersonnelNos.includes(item.Personnel_No)
        )
      );
    }
  }, [searchTerm, selectedItems, personnelArray]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSelect = (item) => {
    onSelect(item);
    setSearchTerm("");
  };

  const capitalizeFirstLetter = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join("");
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Search..."
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      {searchResults.length > 0 && (
        <div className="left-0 right-0 mt-2 border border-gray-300 bg-white rounded-md max-h-[40vh] overflow-y-auto">
          {searchResults.map((item) => (
            <div
              key={item.Personnel_No}
              className="px-4 py-2 flex"
            >
              {`#${item.Personnel_No} ${capitalizeFirstLetter(
                item.First_Name
              )} ${capitalizeFirstLetter(item.Last_Name)}`}
              <div className="grow text-right">
                <button
                  onClick={() => handleSelect(item)}
                  className="ml-2 text-orange-500 hover:text-orange-400 font-semibold"
                >
                  Add
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchInput;
