
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';


function ChinaTime() {
  const [chinaTime, setChinaTime] = useState(null);

  useEffect(() => {
    // Function to fetch and update China time
    const fetchAndSetChinaTime = async () => {
      try {
        const response = await fetch('http://worldtimeapi.org/api/timezone/Asia/Shanghai');
        const data = await response.json();
        // Extract the time from the response
        const time = data.datetime;
        const formattedTime = formatTime(time);
        //console.log(formattedTime);
        setChinaTime(formattedTime);
      } catch (error) {
        console.error('Error fetching China time:', error);
      }
    };

    // Initial fetch on component mount
    fetchAndSetChinaTime();

    // Set interval to fetch and update China time every minute
    const intervalId = setInterval(fetchAndSetChinaTime, 1000); // Fetch every minute
    
    // Cleanup function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once on mount


  const formatTime = (time) => {
    const dateTime = DateTime.fromISO(time, { zone: 'Asia/Shanghai' });
    return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  };

  return (

        <div className = " w-full rounded-md invisible md:visible bg-white text-left md:flex items-center space-x-1">
            <p className = " text-[#666666] ml-3">China Local Time: </p>
            <p className = "font-semibold">{chinaTime}</p>
        </div>

  );
}

export default ChinaTime;

  