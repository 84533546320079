import React from "react";
import { SliderData } from "../components/SliderData";
import ImageSlider from "../components/ImageSlider";
import "../Styles/Home.css";
import axios from "axios";
import { FaAngleDown, FaBook, FaFlask, FaUsers ,FaEnvelope, FaWeixin} from "react-icons/fa";
const Home = () => {
  return (
    <div className="min-h-screen  text-blavk">
      <div className="h-[10vh]"></div>

      <main className="flex flex-col items-center justify-center flex-grow">
        <section
          className="text-center relative w-screen h-[90vh] px-10"
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1603657523988-76184975d205?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bW91bnRhaW4lMjBzdW5zZXR8ZW58MHx8MHx8fDA%3D&w=1000&q=80')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "90vh",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div className="relative flex flex-col justify-center items-center h-full text-white">
            <h2 className="text-5xl font-bold mb-4 z-10">
              Empower Through Education: Building Futures Together
            </h2>
            <p className="text-lg mb-8 z-10">
              Join Our Vibrant Community of Student Tutors and Start Making a
              Difference Today!
            </p>
            <p className="text-white font-bold py-2 px-4 rounded flex items-center z-10">
              Explore More
            </p>
            <FaAngleDown className="ml-2" /> {/* Down arrow icon */}
          </div>
        </section>

        <section className="py-16 bg-gray-100 w-[100vw]">
          <div className="container mx-auto px-4 max-w-4xl">
            <div className="text-center">
              <h2 className="text-3xl font-bold mb-4">About Us</h2>
              <hr className="w-20 mx-auto border-b-2 border-[#FF7605] mb-8" />
              <p className="text-lg text-gray-700 mx-10">
              Ignite Youth Club is a globally-oriented organization focused on sparking passions and igniting futures. Led by university students throughout North America, Ignite Education is committed to providing a better future for students from diverse backgrounds and age groups by enhancing their education through keynote speeches, classes, and competitions. Whether it be STEM or humanities, debate or coding, Ignite Education hopes to see students find their passions, and burn bright, wherever those passions lead them.
              </p>
            </div>
          </div>
        </section>

        <section 
  style={{
    backgroundImage: "url('https://images.unsplash.com/photo-1519414442781-fbd745c5b497?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bW91bnRhaW4lMjBzdW5zZXR8ZW58MHx8MHx8fDA%3D&w=1000&q=80')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    
  }}
  className="py-10 md:flex justify-center items-center text-center bg-gray-100 text-black w-screen"
>
  <div className="flex flex-col md:flex-row max-w-[900px] bg-white rounded-md p-4 mx-4 md:mx-auto items-center">
    <div className="flex flex-col items-center md:flex-row justify-around md:space-x-8 text-center">
      <div className="ml-4 md:mx-0 my-3 md:my-0">
        <h4 className="text-4xl font-bold text-[#FF7605]">6+</h4>
        <p className="text-xl text-gray-700">Years of Experience</p>
      </div>
      
      <div className="mx-4 md:mx-0 my-3 md:my-0">
        <h4 className="text-4xl font-bold text-[#FF7605]">100+</h4>
        <p className="text-xl text-gray-700">Active Students</p>
      </div>
     
      <div className="mx-4 md:mx-0 my-3 md:my-0">
        <h4 className="text-4xl font-bold text-[#FF7605]">500+</h4>
        <p className="text-xl text-gray-700">Classes Taught</p>
      </div>
    </div>
  </div>
</section>

        <section className="w-screen text-center py-20 bg-gray-100 text-gray-100">
  <div className="max-w-7xl mx-auto px-4">
    <h3 className="text-3xl font-bold mb-4 text-black">What We Offer</h3>
    <hr className="w-20 mx-auto border-b-2 border-[#FF7605] mb-8" />
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div className="bg-[#FF7605] p-5 rounded-lg flex flex-col items-center justify-center">
        <FaBook className="text-white text-5xl mb-4" /> {/* Icon for Humanities */}
        <h4 className="text-2xl font-bold mb-2">Humanities</h4>
        <p>Learn, create, and express yourself through English Programs</p>
      </div>
      <div className="bg-[#FF7605] p-5 rounded-lg flex flex-col items-center justify-center">
        <FaUsers className="text-white text-5xl mb-4" /> {/* Icon for Community Involvement */}
        <h4 className="text-2xl font-bold mb-2">Community Involvement</h4>
        <p>Volunteer or become part of a passionate team of high school organizations.</p>
      </div>
      <div className="bg-[#FF7605] p-5 rounded-lg flex flex-col items-center justify-center">
        <FaFlask className="text-white text-5xl mb-4" /> {/* Icon for STEM Courses */}
        <h4 className="text-2xl font-bold mb-2">STEM Courses</h4>
        <p>Immerse yourself in STEM fields with opportunities through our programs</p>
      </div>
    </div>
  </div>
</section>


<section className="pb-20 text-center w-screen bg-gray-100">
  <div className="max-w-7xl mx-auto px-4">
    <h3 className="text-3xl font-bold mb-6">Interested? Lets talk!</h3>
    <hr className="w-20 mx-auto border-b-2 border-[#FF7605] mb-8" />
    <p className="text-gray-700 mb-6">For more information, contact us:</p>
    <div className="flex flex-col items-center justify-center bg-white p-8 rounded-lg shadow-md mx-auto" style={{ maxWidth: '500px' }}>
      <div className="flex items-center mb-4">
        <FaEnvelope className="text-[#FF7605] text-3xl mr-4" />
        <p className="text-gray-700 text-lg">IgniteYouthClub@gmail.com</p>
      </div>
      {/* <div className="flex items-center">
        <FaWeixin className="text-[#FF7605] text-3xl mr-4" />
        <p className="text-gray-700 text-lg">yourwechatid</p>
      </div> */}
    </div>
  </div>
</section>

      </main>
    </div>
  );
};

export default Home;
