import React from "react";
import axios from "axios";
import ProfileMenu from "../components/ProfileMenu";
import TimesheetSubmission from "../components/TimesheetSubmission";
import { useEffect, useState } from "react";
import ChinaTime from "../components/Clock";

export const MyAccount = () => {
  axios.defaults.withCredentials = true;

  const [currentPayoutEmail, setCurrentPayoutEmail] = useState("Error");
  const [joinDate, setJoinDate] = useState("Error");
  const [totalHours, setTotalHours] = useState("Error");
  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [payoutChanged, setpayoutChanged] = useState(false);

  const [accountDetails, setAccountDetails] = useState({
    accountEmail: "",
    First_Name: "",
    Last_Name: "",
  });
  const [newPassword, setNewPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [payoutEmail, setPayoutEmail] = useState({
    NewPayout: ''
  });

  useEffect(() => {
    axios.get(process.env.REACT_APP_Apiurl + "login").then((res) => {
      if (!res.data.Authorized) {
        window.location.href = process.env.REACT_APP_App_Url;
      }
    });

    axios.get(process.env.REACT_APP_Apiurl + "user/data").then((res) => {
        setJoinDate(res.data.UserInfo[0].Join_Date);
        setCurrentPayoutEmail(res.data.UserInfo[0].Payout_Email);
        setTotalHours(res.data.UserInfo[0].Total_Hours);
      accountDetails.First_Name = res.data.UserInfo[0].First_Name
      accountDetails.Last_Name = res.data.UserInfo[0].Last_Name
      accountDetails.accountEmail = res.data.UserInfo[0].AccountEmail

      payoutEmail.NewPayout = res.data.UserInfo[0].Payout_Email;   

      document.getElementById('NewPayout').value = res.data.UserInfo[0].Payout_Email
      document.getElementById('First_Name').value = res.data.UserInfo[0].First_Name
      document.getElementById('Last_Name').value = res.data.UserInfo[0].Last_Name
      document.getElementById('accountEmail').value = res.data.UserInfo[0].AccountEmail
    });



  }, []);



  useEffect(() => {
    axios.get(process.env.REACT_APP_Apiurl + "user/data").then((res) => {
        setCurrentPayoutEmail(res.data.UserInfo[0].Payout_Email);
    });
  }, [payoutChanged]);

  const accountInput = (event) => {
    setShowError(false);
    setSuccess(false);
    setAccountDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));

    console.log(accountDetails);
  };

  const payoutInput = (event) => {
    setShowError(false);
    setSuccess(false);
    setPayoutEmail((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    console.log(payoutEmail);
  };

  const passwordInput = (event) => {
    setShowError(false);
    setSuccess(false);
    setNewPassword((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    console.log(newPassword);
  };

  const handleAccountSubmit = (event) => {
    event.preventDefault();
    
    axios.post(process.env.REACT_APP_Apiurl + "user/UpdateAccountDetails", accountDetails).then((res) => {
        if(res.data.success){
            setSuccess(true);
            setMessage("Account Details Updated!");
            window.scrollTo(0, 0);
        }else{
            setSuccess(false);
            setShowError(true)
            setMessage(res.data.error);
        }
    });
    
  }
  const handlePayoutSubmit = (event) => {
    event.preventDefault();
    console.log(payoutEmail)
    axios.post(process.env.REACT_APP_Apiurl + "user/ChangePayoutEmail", payoutEmail ).then((res) => {
        if(res.data.success){
            setSuccess(true);
            setMessage("Payout Email Updated!");
            setpayoutChanged(!payoutChanged)
            window.scrollTo(0, 0);
        }else{
            setSuccess(false);
            setShowError(true)
            setMessage(res.data.message);
        }
    });
    
  }
  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    
    if(newPassword.newPassword.length < 7){
        setSuccess(false);
        setShowError(true);
        setMessage("Password must be more than 8 characters")
        return
    }

    if(newPassword.newPassword != newPassword.confirmNewPassword){
        setSuccess(false);
        setShowError(true);
        setMessage("Passwords do not match!")
        return
    }

    console.log("here")

    axios.post(process.env.REACT_APP_Apiurl + "user/ChangePassword", newPassword).then((res) => {
        if(res.data.success){
            setSuccess(true);
            setMessage("Password Updated!");
            window.scrollTo(0, 0);
        }else{
            setSuccess(false);
            setShowError(true)
            setMessage(res.data.message);
        }
    });

    
  }



  return (
    <div className="bg-gray-200 w-[100vw] min-h-[100vh] h-[100%] flex flex-row pb-4">
      <div className="min-w-[150px] w-[15vw]">
        <ProfileMenu />
      </div>
      <div className="flex-col h-[100%] w-[85%] border-x-[2vw] ">
        <div className=" w-full   mt-[4vh] flex md:space-x-4">
          <div className="py-2 shadow-md min-w-[60%] rounded-md bg-white text-left flex items-center space-x-1 ">
            <p className="font-semibold ml-4">My Account</p>
          </div>
          <ChinaTime />
        </div>

        <div className="w-full h-[100%] flex flex-col">
          {/*User Info*/}
          <div className="flex py-4 justify-stretch">
            <div className="shadow-md rounded-md bg-white p-3 text-center grow">
              <p className="text-lg font-semibold">{currentPayoutEmail}</p>
              <div className=" flex mx-3 mt-1 justify-center ">
                <p className="text-[#666666] text-sm">Payout Email</p>
              </div>
            </div>

            <div className="shadow-md rounded-md bg-white p-3 text-center items-center justify-center flex ml-4 grow">
              <div>
                <p className={`text-lg font-medium `}>{joinDate}</p>
                <p className="text-[#666666] text-sm">Account Creation Date</p>
              </div>
            </div>

            <div className="shadow-md rounded-md bg-white p-3 text-center ml-4 grow items-center justify-center flex">
              <div>
                <p className={`text-lg font-medium `}>{totalHours}</p>
                <p className="text-[#666666] text-sm">Logged Hours</p>
              </div>
            </div>
          </div>

           <div className = {!showError ? "hidden" : "bg-red-100 border mb-4 border-red-400 text-red-700 px-4 py-3 rounded relative"} role="alert">
              <strong className="font-bold">Error! </strong>
              <span className="block sm:inline">{message}</span>
            </div>

            <div className = {!success ? "hidden" : "bg-green-100 border mb-4 border-green-400 text-green-700 px-4 py-3 rounded relative"} role="alert">
              <strong className="font-bold">Success! </strong>
              <span className="block sm:inline">{message}</span>
            </div>

          {/*Account Details*/}

          <div className=" shadow-md rounded-md bg-white pt-4 pb-5 flex-col">
            <div className=" w-full text-left relative">
              <p className="text-lg ml-4 font-semibold">Account Details</p>
            </div>


            <form onSubmit={handleAccountSubmit}>
              <div className="flex">
                <div className="mx-4">
                  <label
                    htmlFor="accountEmail"
                    className="block text-gray-700 text-sm font-semibold"
                  >
                    Account Email
                  </label>
                  <input
                    required
                    onChange={accountInput}
                    id="accountEmail"
                    type="email"
                    name="accountEmail"
                    placeholder="Account Email"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="pr-4">
                  <label
                    htmlFor="First_Name"
                    className="block text-gray-700 text-sm font-semibold "
                  >
                    First Name
                  </label>
                  <input
                    onChange={accountInput}
                    id="First_Name"
                    required
                    type="text"
                    placeholder="First Name"
                    name="First_Name"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="pr-4">
                  <label
                    htmlFor="Last_Name"
                    className="block text-gray-700 text-sm font-semibold "
                  >
                    Last Name
                  </label>
                  <input
                    required
                    onChange={accountInput}
                    id="Last_Name"
                    placeholder="Last Name"
                    type="text"
                    name="Last_Name"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
              <div className="ml-4 mt-2">
                <button type = "submit" className=" text-sm px-2 py-0.5 rounded-md bg-orange-500 cursor-pointer text-white hover:bg-orange-400 mr-4 font-semibold">
                  Update Details
                </button>
              </div>
            </form>
          </div>

          <div className=" shadow-md rounded-md bg-white mt-4  pt-4 pb-5 flex-col">
            <div className=" w-full text-left relative">
              <p className="text-lg ml-4 font-semibold">Payout Email</p>
            </div>
            <form onSubmit={handlePayoutSubmit}>
              <div className="flex">
                <div className="mx-4">
                  <label
                    htmlFor="NewPayout"
                    className="block text-gray-700 text-sm font-semibold"
                  >
                    Email
                  </label>
                  <input
                  onChange={payoutInput}
                    required
                    id="NewPayout"
                    type="email"
                    name="NewPayout"
                    placeholder="Payout Email"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
              <div className="ml-4 mt-2">
                <button className=" text-sm px-2 py-0.5 rounded-md bg-orange-500 cursor-pointer text-white hover:bg-orange-400 mr-4 font-semibold">
                  Change Email
                </button>
              </div>
            </form>
          </div>

          <div className=" shadow-md rounded-md bg-white mt-4  pt-4 pb-5 flex-col">
            <div className=" w-full text-left relative">
              <p className="text-lg ml-4 font-semibold">Change Password</p>
            </div>
            <form onSubmit={handlePasswordSubmit}>
              <div className="flex">
                <div className="mx-4">
                  <label
                    htmlFor="oldPassword"
                    className="block text-gray-700 text-sm font-semibold"
                  >
                    Current Password
                  </label>
                  <input
                  onChange={passwordInput}
                    required
                    id="oldPassword"
                    type="password"
                    name="oldPassword"
                    placeholder="Current Password"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="pr-4">
                  <label
                    htmlFor="newPassword"
                    className="block text-gray-700 text-sm font-semibold "
                  >
                    New Password
                  </label>
                  <input
                  onChange={passwordInput}
                    id="newPassword"
                    required
                    type="password"
                    placeholder="New Password"
                    name="newPassword"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="pr-4">
                  <label
                    htmlFor="confirmNewPassword"
                    className="block text-gray-700 text-sm font-semibold "
                  >
                    Confirm New Password
                  </label>
                  <input
                  onChange={passwordInput}
                    id="confirmNewPassword"
                    required
                    placeholder="Confirm New Password"
                    type="password"
                    name="confirmNewPassword"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
              <div className="ml-4 mt-2">
                <button className=" text-sm px-2 py-0.5 rounded-md bg-orange-500 cursor-pointer text-white hover:bg-orange-400 mr-4 font-semibold">
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyAccount;
