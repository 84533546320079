import React, { useState ,Link} from 'react';
import HoverBox from '../components/Hover';

import pic from "../assets/alacrity.png";
import pic2 from "../assets/bethel.webp";
const Program = () => {

  const [currentBox, setCurrentBox] = useState(0);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight } = e.target;
    const newBox = Math.round(scrollTop / clientHeight);
    if (newBox !== currentBox) {
      setCurrentBox(newBox);
    }
  };

  return (
    <div className="relative h-screen overflow-auto snap-y snap-mandatory" onScroll={handleScroll}>
      <div className="fade-overlay top-0"></div>
      <div className="fade-overlay bottom-0"></div>

      <div className="snap-start h-screen flex items-center justify-center transition-transform duration-500 ease-in-out">
        <div className={`w-3/5 h-3/5 flex items-center rounded-lg justify-center transition ease-in-out delay-150 ${currentBox === 0 ? 'bg-blue-900 hover:opacity-90 hover:-translate-y-1 hover:scale-105' : 'bg-gray-300 hover:bg-gray-400'}`}>
          {/* <div className = "flex justify-center items-center flex-row">
            <img defaultLogo = "w-[15vw] aspect-square "src={pic} alt  = "default"></img>
          </div> */}
          <HoverBox defaultText="ALACRITY CODE" defaultLogo = {pic} subText = "ASSOCIATION" hoverText="Alacrity is a student-led organization and club intended to help teach practical coding skills to absolute beginners as well as set up a valuable community for those passionate about programming.." />
          
        </div>
      </div>

      <div className="snap-start h-screen flex items-center justify-center transition-transform duration-500 ease-in-out">
        <div className={`w-3/5 h-3/5 flex items-center rounded-lg justify-center transition ease-in-out delay-200 ${currentBox === 1 ? 'bg-orange-500 hover:opacity-90 hover:-translate-y-1 hover:scale-105' : 'bg-gray-300 hover:bg-gray-400'}`}>
            <HoverBox defaultText="IGNITE X AIBAIFU" subText = "Volunteering" subLogo = {pic2} defaultSVG = "M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" defaultSVGPath = "M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" hoverText="Since July of 2022, Ignite has had an ongoing partnership with Chinese organization AiBaiFu (Bethel) to provide free English tutoring to special needs children" />
        </div>
      </div>

      <div className="snap-start pt-14 h-screen flex items-center justify-center transition-transform duration-500 ease-in-out flex-col">
        <div className={`w-3/5 h-3/5 flex items-center rounded-lg justify-center transition ease-in-out delay-200 ${currentBox === 2 ? 'bg-orange-500 hover:opacity-90 hover:-translate-y-1 hover:scale-105' : 'bg-gray-300 hover:bg-gray-400'}`}>
          <HoverBox defaultText="English Reading" subText = "Buddies" defaultSVG = "M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" hoverText="English Buddies is an international program connecting youth from China to high school students in Canada. For over 4 years, this program has provided enriching opportunities for students to discover and learn about western culture, landscape and traditions. In doing so, students are able to gain crucial skills in English communication, reading and writing." />
        </div>
        <div className = "pt-5 w-[20vw] text-center">
        <p className = "font-md text-sm">Want to take a more active role in your community? <a href="./Contact-Us" class="font-medium text-orange-600 underline dark:text-orange-500 hover:no-underline">Contact us</a> for opportunities to volunteer in your community! </p>
      </div>
      <hr className=" my-4 bg-gray-200 border-2 w-[10vw] border-orange-400 rounded"></hr>
      </div>



      

     
    </div>

    


  );
};
export default Program;