import React from 'react'
import { useHistory } from 'react-router-dom'
import ProfileMenu from '../components/ProfileMenu'
import Chinaclock from '../components/Clock'
import WelcomeHeader from '../components/WelcomeHeader'
import Session from '../components/Session'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

export const UserSessions = () => {
    axios.defaults.withCredentials = true;
    const [availableSessions, setAvailableSessions] = useState(null);

    useEffect(() => {

        axios.get(process.env.REACT_APP_Apiurl + "login")
        .then((res) => {
            if(!res.data.Authorized){
            window.location.href = process.env.REACT_APP_App_Url
            }
        })


        axios.get(process.env.REACT_APP_Apiurl + "user/Sessions")
          .then((res) => {
            if(res.data.message === "No Sessions Found"){
                setAvailableSessions(null);
            }else{
                setAvailableSessions(res.data.session)
            }
            
          })

          console.log(availableSessions);
      }, []);

    const handleItemClick = (Num) => {
        var targetUrl = process.env.REACT_APP_App_Url + "Profile/Sessions/" + Num;
        window.location.href = targetUrl;
    };

  return (
    <div className = "bg-gray-200 h-[100%] w-[100vw] flex flex-row pb-4 ">
        
        <div className='w-[15%] h-[100vh]'>
            <ProfileMenu/>
        </div>
        
        <div className="flex-col w-[85%] border-x-[2vw]">
            <div className = " w-full  h-[5%] mt-[4vh] flex md:space-x-4">
                <div className = " min-w-[60%] rounded-md bg-white text-left flex items-center space-x-1 ">
                    <p className = "font-semibold ml-4">Timesheet Portal</p>
                </div>
                <Chinaclock/>
            </div>
            <div className='w-full'>
                <div className=' max-h-[800px] w-full my-4 rounded-lg pb-6 bg-white text-center relative'>
                    <p className='text-2xl pt-[3.5vh] font-bold'>Available Sessions</p>
                    <p className='text-sm pb-[2vh]'>Click to view session</p>

                        <div className='overflow-y-auto h-[42rem] flex-wrap'>
                            {(availableSessions == null) ? (
                                <div>No Session Data Found</div>
                            ) : (
                                /* Map over the array of fetched items */
                                availableSessions.map((item) => ( 
                                <div key={item.SessionNo} onClick = {() => handleItemClick(item.SessionNo)}>
                                    <Session 
                                    SessionNum = {item.SessionNo}
                                    StartDate = {item.Start}
                                    EndDate = {item.End}
                                    DueDate = {item.Due}
                                    Status = {item.SessionStatus}
                                    SubmissionDate = {item.SubmissionDate}
                                    userStatus= {item.UserStatus}/>
                                </div>
                                ))
                            )}
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserSessions