import React from 'react'
import { useParams } from 'react-router-dom'
import ProfileMenu from '../components/ProfileMenu'
import Chinaclock from '../components/Clock'
import {useEffect, useState } from 'react'
import axios from 'axios'


export const AdminTimeSheet = () => {
    axios.defaults.withCredentials = true;  
    const classTypes = {
        Regular: 1,
        Substitute: 2,
        Volunteer: 3,
        AiBaiFu: 4
      };

      const [sessionTotals, setSessionTotals] = useState({
        paidHours: 0,
        volunteerHours:0
      })

    const [availableSessions, setAvailableSessions] = useState([]);
    const{id, personnelNum} = useParams();
    const [paidHours, setPaidHours] = useState(0.0);
    const [volunteerHours, setvolunteerHours] = useState(0.0);
    const [classError, setClassError] = useState(false);
    const [dateError, setdateError] = useState(false);
    const [durationError, setdurationError] = useState(false);
    const [TypeError, setTypeError] = useState(false);
    const [sessionData, setSessionData] = useState({
        Start: "Error",
        End: "Error",
      });
      const [payoutEmail, setPayoutEmail] = useState("");

    const [submission, setSubmission] = useState({
        Class: '',
        Date: '',
        Hours: '',
        Type: ''
      })

      const [editId, setEditId] = useState(-1);
      const [editing, setEditting] = useState(false);
      const [submissionEdit, setSubmissionEdit] = useState({
        Class: "",
        Date: "",
        Hours: "",
        Type: "",
        submissionNumber: ""
      });
    
      const handleInput = (event) => {
        setClassError(false);
        setdateError(false);
        setdurationError(false);
        setTypeError(false);
        console.log(classError)
        setSubmission(prev => ({ ...prev,[event.target.name]: [event.target.value]}))
      }

      function clearInputs() {
        document.getElementById('Class').value = ''; // Clear input 1
        document.getElementById('Date').value = ''; // Clear input 2
        document.getElementById('Hours').value = ''; // Clear input 1
        document.getElementById('Type').value = -1; // Clear input 2
        setSubmission({
        Class: '',
        Date: '',
        Hours: '',
        Type: ''
      })
      }

      const populateSubmissions = () => {
        axios.get(process.env.REACT_APP_Apiurl + "admin/user/submissions/"+ id+"/"+ personnelNum)
        .then((res) => {
            setAvailableSessions(res.data);
            console.log(res)

            var volunteerHourstemp = 0.0;
            var paidHourstemp = 0.0;

            for (let i = 0; i < res.data.length; i++) {
                // Add the integer parameter to each object
                console.log(res.data[i])
                if(res.data[i].Class_Type === "Regular"){
                    paidHourstemp += res.data[i].Hours;
                    volunteerHourstemp += res.data[i].Hours;
                }else if(res.data[i].Class_Type === "Volunteer"){
                    volunteerHourstemp += res.data[i].Hours;
                }else if(res.data[i].Class_Type === "AiBaiFu"){
                    volunteerHourstemp += res.data[i].Hours;
                }else if(res.data[i].Class_Type === "Substitute"){
                    paidHourstemp += res.data[i].Hours;
                    volunteerHourstemp += res.data[i].Hours;
                }
                
              }
              sessionTotals.paidHours = paidHourstemp.toFixed(1);
            sessionTotals.volunteerHours = volunteerHourstemp.toFixed(1);
              axios.post(process.env.REACT_APP_Apiurl + "admin/update/Total/" + id + "/" + personnelNum, sessionTotals);
        })
      }

      const handleAdd = () => {

        if(submission.Class === ''){
            setClassError(true);
            console.log(classError)
            return
        }
        if(submission.Date === ''){
            setdateError(true);
            return
        }
        if(submission.Hours === ''){
            setdurationError(true);
            return
        }
        if(submission.Type === ''){
            setTypeError(true);
            return
        }
        if(submission.Hours > 10){
            setdurationError(true);
            return
        }

        axios.post(process.env.REACT_APP_Apiurl + "admin/user/submissions/"+ id+"/"+ personnelNum,submission)
        .then((res) => {
            console.log(res.data);
            populateSubmissions();
            clearInputs();
        })
      }

      const deleteEntry = (submissionId) => {
        axios.post(process.env.REACT_APP_Apiurl + "admin/submission/delete/"+submissionId+"/"+ id +"/" + personnelNum)
        .then((res) => {
            populateSubmissions();
        })
      }

    useEffect(() => {

        axios.get(process.env.REACT_APP_Apiurl + "login")
        .then((res) => {
            if(!res.data.Authorized){
            console.log(res)
            window.location.href = process.env.REACT_APP_App_Url
            }
        })

        populateSubmissions();

        axios.get(process.env.REACT_APP_Apiurl + "admin/session/"+id +"/"+personnelNum)
        .then((res) => {
            setPayoutEmail(res.data.Payout_Email[0].Payout_Email);
            sessionData.Start = res.data.session[0].Start;
            sessionData.End = res.data.session[0].End;
        })



        console.log(classError)
    }, []);


    function convertDateFormat(dateStr) {
        const [month, day, year] = dateStr.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    
      const editEntry = (item) => {
        console.log(item);
    
        submissionEdit.Class = item.Class_No;
        submissionEdit.Date = convertDateFormat(item.Date);
        submissionEdit.Hours = item.Hours;
        submissionEdit.Type = classTypes[item.Class_Type];
        submissionEdit.submissionNumber = item.Submission_No;
    
        setEditId(item.Submission_No);
        setEditting(true);
        
      };
    
      const cancelEdit = () => {
        setEditId(-1);
        setEditting(false);
        submissionEdit.submissionNumber = "-1";
      };
    
      const submitEdit = () => {
        
        //TODO: Error Checking
        axios.post(process.env.REACT_APP_Apiurl + "user/update/submission", submissionEdit).then((res) => {
            if(res.data.success){
                setEditId(-1);
                setEditting(false);
                populateSubmissions();
            }
          });
    
      };
    
      const handleEditInput = (event) => {
        setSubmissionEdit((prev) => ({
          ...prev,
          [event.target.name]: [event.target.value],
        }));
      };
    
    
      useEffect(() => {
        if (editing) {
          // Set values after the component is rendered
          document.getElementById("editClassNo").value = submissionEdit.Class;
          document.getElementById("editDate").value = submissionEdit.Date;
          document.getElementById("editType").value = submissionEdit.Type;
          document.getElementById("editHours").value = submissionEdit.Hours;
        }
      }, [editing, editId]);

  return (
    <div className = "bg-gray-200 w-[100vw] h-[100vh] flex flex-row">
        
        <div className='w-[15%] h-[100vh]'>
            <ProfileMenu/>
        </div>
        
        <div className="flex-col w-[85%] border-x-[2vw]">
            <div className = " w-full  h-[5%] mt-[4vh] flex md:space-x-4">
                <div className = " min-w-[60%] rounded-md bg-white text-left flex items-center space-x-1 ">
                    <p className = "font-semibold ml-4">Session {id} TimeSheet </p>
                </div>
                <Chinaclock/>
            </div>
            <div className='w-full h-[87vh] flex flex-col'>

                {/*Session Info*/}
                <div className='flex py-4 justify-stretch'>
                    <div className='rounded-md bg-white p-3 text-center grow max-w-[300px]'>
                        <p className='font-semibold'>{payoutEmail}</p>
                        <div className=' flex mx-3 mt-1 justify-center '>
                            <p className='text-[#666666] text-sm'>Payout Email</p>
                            
                        </div>
                    </div>
                    <div className='rounded-md bg-white p-3 text-center ml-4 grow'>
                        <p className='text-lg font-medium'>{sessionData.Start} - {sessionData.End}</p>
                        <p className='text-[#666666] text-sm'>Session Duration</p>
                    </div>
                    <div className='rounded-md bg-white p-3 text-center ml-4 grow'>
                        <p className='text-lg font-medium'>{sessionTotals.volunteerHours}</p>
                        <p className='text-[#666666] text-sm'>Volunteer Hours</p>
                    </div>
                    <div className='rounded-md bg-white p-3 text-center ml-4 grow'>
                        <p className='text-lg font-medium'>{sessionTotals.paidHours}</p>
                        <p className='text-[#666666] text-sm'>Paid Hours</p>
                    </div>
                </div>

                {/*timesheet*/}

                <div class="bg-white mb-4 flex text-center rounded-md py-4 text-sm">
                    <div className='w-full mx-5'>
                        <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Class ID</label>
                        <input  id = "Class" required form = "timesheet" onChange={handleInput} type="text" name="Class" placeholder="Class ID" className= {` ${!classError ? 'border-gray-300' : ' border-red-400 bg-red-100'} w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}/>
                    </div>
                    <div className='w-full mx-4'>
                        <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Date</label>
                        <input  id = "Date" required form = "timesheet" onChange={handleInput} type="date" name="Date" placeholder="Date" className={`${!dateError ? 'border-gray-300' : ' border-red-400 bg-red-100'} w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}/>
                    </div>
                    <div className='w-full mx-4'>
                        <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Duration</label>
                        <input  id = "Hours" required form = "timesheet" onChange={handleInput} type="text" name="Hours" placeholder="Hours" className={`${!durationError ? 'border-gray-300' : ' border-red-400 bg-red-100'} w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}/>
                    </div>
                    <div className='w-full mx-4'>
                        <label for="Class" class="block text-gray-700 text-sm font-semibold mb-2">Class Type</label>
                        <select  id = "Type" required form = "timesheet" onChange={handleInput} name="Type" className={`${!TypeError ? 'border-gray-300' : ' border-red-400 bg-red-100'} w-full text-center border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500`}>
                            <option value= {-1} disabled selected>Class Type</option>
                            <option value= {1}>Regular</option>
                            <option value= {2}>Substitute</option>
                            <option value= {3}>Volunteer</option>
                            <option value= {4}>AiBaiFu</option>
                    </select>
                    </div>

                    <button form = "timesheet" onClick = {handleAdd} type="submit" class="bg-orange-500 rounded-md px-4 mx-5 my-1 font-medium text-white hover:bg-orange-400">Add</button>
                </div>
                    
                <div class=" shadow-md rounded-md bg-white h-[66vh] overflow-auto">
                    <table class=" w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="sticky top-0 text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="pl-6 py-3">
                                    No.
                                </th>
                                <th scope="col"b class="pl-4 pr-6 py-3">
                                    Class ID
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Class Type
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Duration
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Options
                                </th>
                            </tr>
                        </thead>
                        
                        <tbody>
                {availableSessions === null ? (
                  <div>Error: Failed to fetch data</div>
                ) : (
                  /* Map over the array of fetched items */
                  availableSessions.map((item, i) => (
                    <tr
                      key={item.Submission_No}
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td class="pl-6 py-4">{availableSessions.length - i}</td>
                      <th
                        scope="row"
                        class="py-4 font-medium text-gray-900 dark:text-white"
                      >
                        {editing && editId === item.Submission_No ? (
                          <input
                            id="editClassNo"
                            className="max-w-[100px]  text-center border border-gray-300 py-1 rounded-md focus:outline-none focus:ring focus:border-blue-500"
                            name ="Class"
                            type="text"
                            placeholder="Class ID"
                            onChange={handleEditInput}
                          />
                        ) : (
                          item.Class_No
                        )}
                      </th>
                      <td class=" py-4">
                        {editing && editId === item.Submission_No ? (
                          <input
                            id="editDate"
                            className=" text-center border border-gray-300 py-1 rounded-md focus:outline-none focus:ring focus:border-blue-500"
                            type="date"
                            name ="Date"
                            placeholder={item.Date}
                            onChange={handleEditInput}
                          />
                        ) : (
                          item.Date
                        )}
                      </td>
                      <td class="py-4">
                        {editing && editId === item.Submission_No ? (
                          <select
                          id="editType"
                          required
                          name="Type"
                          onChange={handleEditInput}
                          className={`${
                            !TypeError
                              ? "border-gray-300"
                              : " border-red-400 bg-red-100"
                          }  text-center border border-gray-300 py-1 rounded-md focus:outline-none focus:ring focus:border-blue-500`}
                        >
                          <option value={-1} disabled selected>
                            Class Type
                          </option>
                          <option value={1}>Regular</option>
                          <option value={2}>Substitute</option>
                          <option value={3}>Volunteer</option>
                          <option value={4}>AiBaiFu</option>
                        </select>
                        ) : (
                          item.Class_Type
                        )}</td>
                      <td class="py-4">
                      {editing && editId === item.Submission_No ? (
                          <input
                            id="editHours"
                            className=" text-center border border-gray-300 max-w-[100px] py-1 rounded-md focus:outline-none focus:ring focus:border-blue-500"
                            type="number"
                            name ="Hours"
                            onChange={handleEditInput}
                            placeholder="Duration"
                          />
                        ) : (
                          item.Hours === 1 ? item.Hours + " Hour" : item.Hours + " Hours"
                        )} 
                      </td>
                      <td class=" py-4 flex ">
                        <div className="text-center grow ">
                          {editing && editId === item.Submission_No ? (
                                <div>
                              <button
                                onClick={submitEdit}
                                class="py-1 font-medium text-orange-500 hover:text-orange-400"
                              >
                                Update
                              </button>
                              <button
                                onClick={cancelEdit}
                                class="py-1 ml-3 font-medium text-red-500 hover:text-red-400"
                              >
                                Cancel
                              </button>
                            </div>
                              ) : (
                                <div>
                                  <button
                                    onClick={() => editEntry(item)}
                                    class="font-medium text-orange-500 hover:text-orange-400"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => deleteEntry(item.Submission_No)}
                                    class="ml-3 font-medium text-red-500 hover:text-red-400"
                                  >
                                    Delete
                                  </button>
                                </div>
                              )
                          }
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

  )
}

export default AdminTimeSheet