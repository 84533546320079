import React, {useCallback, useEffect, useState} from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import Logo from '../assets/Ignite-Logo.png'
import { Link } from 'react-router-dom'
import axios from 'axios'

const Header = () => {
    axios.defaults.withCredentials = true;
    const [loggedIn, setLogin] = useState(false);

    useEffect(() => {
        console.log(process.env.REACT_APP_Apiurl)
        axios.get(process.env.REACT_APP_Apiurl + "login")
          .then((res) => {
            setLogin(res.data.login);
            console.log(res);
          })
      })

    
    const[nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

  return (
    <div className = 'fixed w-full bg-white h-[100px] flex justify-between items-center px-4 text-black border-bottom z-50' >

        <div>
        </div>
        <div className='md:hidden'></div>
        <div>
            <ul className='hidden md:flex font-semibold nav'>
                
                <li>
                    <Link to='Projects'>PROJECTS</Link>
                </li>
                <li>
                    <Link to='Programs'>PROGRAMS</Link>
                </li>
                <li>
                    <Link to='Our-Team'>OUR TEAM</Link>
                </li>

            </ul>
        </div>
        <div>
        <Link to='/'>
            <img src={Logo} alt  = "Ignite Logo" style = {{height: '60px'}}></img>
        </Link>
        </div>
        <div>
            <ul className='hidden md:flex font-semibold nav'>
                <li>
                    <Link to='Contact-Us'>CONTACT US</Link>
                </li>
                <li>
                    <Link to='About-Us'>ABOUT US</Link>
                </li>
                <li>
                    {loggedIn ? (
                        <Link to='/Profile/Sessions'>PROFILE</Link>
                    ) : (
                        <Link to='Log-in'>LOG IN</Link>
                    )}
                </li>
            </ul>
        </div>

        {/* Hamburger/Exit icon*/}
        <div onClick = {handleClick} className='md:hidden z-10'>
            {!nav ? <FaBars />: <FaTimes/>}
        </div>

        {/* Mobile Menu */}
        <ul className= {!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-slate-300 flex flex-col justify-center items-center'} >
            <li className = 'py-6 text-2xl'>
                <Link to='Projects' onClick={handleClick}>PROJECTS</Link>
            </li>
            <li className = 'py-6 text-2xl'>
                <Link to='Programs' onClick={handleClick}>PROGRAMS</Link>
            </li>
            <li className = 'py-6 text-2xl'>
                <Link to='Our-Team' onClick={handleClick}>OUR TEAM</Link>
            </li>
            <li className = 'py-6 text-2xl'>
                <Link to='Contact-Us'onClick={handleClick}>CONTACT US</Link>
            </li>
            <li className = 'py-6 text-2xl'>
                <Link to='About-Us'onClick={handleClick}>ABOUT US</Link>
            </li>
            <li className = 'py-6 text-2xl'>
                {loggedIn ? (
                    <Link to='/Profile/Sessions' onClick={handleClick}>PROFILE</Link>
                ) : (
                    <Link to='Log-in' onClick={handleClick}>LOG IN</Link>
                )}
            </li>
        </ul>
        <div >
        </div>
    </div>
  )
}

export default Header