import React, { useState, useEffect, useRef } from 'react';
import pic from "../assets/alacrity.png"

const HoverBox = ({ defaultText, hoverText, defaultLogo, subText, defaultSVG, subLogo, defaultSVGPath }) => {
    const [isHovered, setIsHovered] = useState(false);
    const boxRef = useRef(null);
  
    useEffect(() => {
      const handleMouseEnter = () => setIsHovered(true);
      const handleMouseLeave = () => setIsHovered(false);
  
      const box = boxRef.current;
      if (box) {
        box.addEventListener('mouseenter', handleMouseEnter);
        box.addEventListener('mouseleave', handleMouseLeave);
      }
  
      return () => {
        if (box) {
          box.removeEventListener('mouseenter', handleMouseEnter);
          box.removeEventListener('mouseleave', handleMouseLeave);
        }
      };
    }, []);
  
    return (
      <div className="relative w-full h-full p-8 box" ref={boxRef}>
        <div className={` absolute inset-0 transition-opacity duration-500 justify-center items-center flex ${isHovered ? 'opacity-0' : 'opacity-100'}`}>
          <div className = "grid-cols-2 flex justify-center items-center ">
            <div className = {`${defaultLogo != null ? 'opacity-100' : 'hidden' }`}>
              <img className = "w-[10vw]" src= {defaultLogo}  />
            </div>
          <div className = "">
            <div className = {`flex justify-center items-center ${defaultSVG !=null ? 'opacity-100' : 'hidden'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="size-12">
              <path fill-rule="evenodd" d={defaultSVG} clip-rule="evenodd" />
              <path d={defaultSVGPath} />
            </svg>
          </div>
              <div className ={` flex flex-col  ${defaultSVG !=null ? 'text-center' : 'text-left'}`}>
              <p className = "text-white text-5xl">{defaultText}</p>
              <p className = "text-gray-100 text-2xl">{subText}</p>
              </div>
             
            
          </div>
            
          </div>
          
          
        </div>
        <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
          <div  className = " flex flex-col gap-2 justify-center items-center ">
            <div className = "flex flex-col text-center">
              <p className="text-xl text-gray-100 w-[30vw] font-thin text-center">
                {hoverText}
              </p>
              <div className = {`${subLogo != null ? 'opacity-100' : 'hidden' } items-center justify-center flex`}>
              <img className = "w-[20vw]" src= {subLogo}  />
            </div>
            </div>
            {/* LEARN MORE 
             <div className = " border-3 border-black">
            <button className = "transition ease-in-out delay-150 p-1 hover:bg-white hover:-translate-y-1 hover:scale-105 hover:text-black duration-300 rounded font-thin text-md text-white">Learn More</button>
            </div> 
            */}
            
          </div>
          
        </div>
      </div>
    );
  };
  
  export default HoverBox;