import React from "react";
import logo from "../assets/ignitewithword.png"
import "../Styles/AboutUs.css";
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return(
        <div className = "max-w-[950px] mx-auto px-2 py-[25px] flex flex-col h-full text-center">
            <div className="h-[10vh]"></div>
            <div className="h-[62vh]">
                <div className = " max-h-[100px] mx-auto px-2  pt-[30px] flex-col text-center">
                 <strong className="text-5xl font-semibold"> 404 </strong> 
                 <h1 className="text-2xl">PAGE NOT FOUND</h1>
                 <p>The page you are looking for was moved or doesn't exist</p>
                </div>
            </div>
        </div>
    )
  }
  
  export default PageNotFound;