import React from 'react'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import BG from '../assets/LoginBG.png'
import axios from 'axios'

const Login = () => {

  const [user, setUser] = useState({
    email: '',
    password: ''
  })

  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const handleInput = (event) => {
    setUser(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    console.log(user);
    setMessage('');
    setShowError(false);
  }

  axios.defaults.withCredentials = true;

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(process.env.REACT_APP_Apiurl + 'login', user)
      .then(async (info) => {
        setMessage(info.data.message);
        if (!info.data.login) {
          setShowError(true);
        }
        else if (info.data.login) {
          localStorage.setItem("token", info.data.token)
          window.location.href = process.env.REACT_APP_App_Url;
        }
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_Apiurl + "login")
      .then((res) => {
        if(res.data.login){
          window.location.href = process.env.REACT_APP_App_Url
        }
      })
  })


  return (
    <div style={{ backgroundImage: `url(${BG})` }} className='xl:bg-contain xl:bg-center xl:bg-no-repeat'>Log-In
      <div className=" mx-auto px-2 pt-[5vh] flex flex-col h-full min-h-[70vh] ">
        <div className="h-[10vh]"></div>
        <div className="bg-white rounded-lg shadow-md w-[350px] h-full pb-5 self-center text-center ">
          <div className="bg-[#f8740c] rounded-t-lg w-full h-[85px]">
            <h1 className="antialiased text-white text-xl font-bold ">Sign In</h1>
          </div>

          <form className='mx-4 mt-5' onSubmit={handleSubmit}>
            <div className="mb-4 text-left">

              <div className={!showError ? "hidden" : "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center"} role="alert">
                <strong className="font-bold">Error! </strong>
                <span className="block sm:inline">{message}</span>
              </div>
              <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email</label>
              <input onChange={handleInput} type="email" id="email" name="email" placeholder="Enter email" className="border  border-gray-300 px-3 py-2 w-full rounded-full focus:outline-none focus:ring focus:border-blue-500" required />
            </div>
            <div className="mb-6 text-left">
              <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">Password</label>
              <input onChange={handleInput} type="password" id="password" name="password" placeholder="Enter password" className="border border-gray-300 px-3 py-2 w-full rounded-full focus:outline-none focus:ring focus:border-blue-500" required />
            </div>
            <button type="submit" className="bg-[#f8740c] text-white font-semibold px-4 py-2 hover:bg-[#ff913c] focus:outline-none focus:ring focus:border-blue-300 w-full rounded-full">Login</button>
          </form>
          <h3 className="pt-[20px] text-center text-gray-400 text-sm font-semibold">Don't have an account?</h3>
          {/* <h2 className = "text-center text-[#f8740c] text-md font-semibold">Sign up now</h2> */}
          {/* <a href="ignite-website/src/pages/Sign-up.jsx" className="text-[#f8740c] text-md font-semibold hover:text-[#ffa45e]">Sign up</a>
                */}
          <Link to='../Sign-up' className="text-[#f8740c] text-md font-semibold hover:text-[#ffa45e]">Sign up</Link>

        </div>

      </div>
    </div>

  )
}

export default Login