import React from 'react';

import { useCallback, useEffect, useState } from 'react'

function Session({SessionNum, StartDate, EndDate, DueDate, Status, IsVisible}) {

    const [colorValue, setColorValue] = useState();
    const [bgColor, setBgColor] = useState();
    useEffect(() => {
        if(IsVisible){
            setBgColor("bg-green-100");
        }else{
            setBgColor("bg-red-100");
        }
        switch (Status) {
            case "Under Review":
            case "Upcoming":
                setColorValue("text-[#666666]");
              break;
            case "Completed":
                setColorValue("text-green-500");
              break;
            case "In Progress":
                setColorValue("text-orange-500");
              break;
        }
      });
      const daysUntilExpiry = (EndDate, Status) => {
        const start = new Date();
        const end = new Date(EndDate);

        if (isNaN(end)) {
            throw new Error("Invalid date format. Please use 'YYYY-MM-DD'.");
        }

        const diffInMs = end - start;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        if(Status == "In Progress"){
        if (Math.ceil(diffInDays) < 0 ){
            return 'Overdue By ' + Math.ceil(diffInDays)*-1 + ' day(s)';
        }
        return 'Due in ' + Math.ceil(diffInDays) + ' day(s)';
    }
    return 'Locked'
    };

  return (
    <div className = {` outline outline-gray-200 shadow-neutral-400 shadow-md outline-2 mx-6 mt-5 divide-y rounded-md hover:bg-gray-100 cursor-pointer`}>
        <div className = 'w-full flex-row flow-root'>
            <div className='float-left flex ml-4 gap-4 outline-none items-middle pt-2'>
                <p className='text-[#353535] text-xl font-bold'> Session {SessionNum}</p>
                <p className='text-sm pt-1'>{StartDate} - {EndDate}</p>
            </div>
            <div className = 'float-right pt-2 mr-2'>
                <p className={`text-xl font-bold ${colorValue}`}> {Status} </p>
            </div>
        </div>
       
        <div className={`${bgColor} w-full flow-root text-right mr-6 bg-gray-200 my-4`}>
            <p className={`float-left ml-4 text-sm my-1 font-semibold`}> {daysUntilExpiry(DueDate, Status)}</p>
            <p className='floar-right text-xs mr-2 my-1'>Due {DueDate}</p>
        </div>
    </div>
  );
}

export default Session 